// @flow

// Import libs
import React, { Component } from "react";
import Stepper from "../Stepper";
import { type ConsultantType } from "../index";

import {
    Dialog as _Dialog,
    DialogContent,
    withMobileDialog,
    AppBar,
    Toolbar,
    IconButton,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

// Define props
type PropsType = {
    open: boolean,
    endCustomer: string,
    invoiceBy: string,
    onClose: (con: ?Object) => void,
    consultant?: ConsultantType,
    fullScreen: boolean,
};

/**
 * A General component
 */
class Dialog extends Component<PropsType> {
    /**
     * Render
     */
    render() {
        const {
            open,
            onClose,
            endCustomer,
            invoiceBy,
            consultant,
            fullScreen,
        } = this.props;

        return (
            <div>
                <_Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={() => onClose(null)}
                >
                    <DialogContent>
                        {fullScreen && (
                            <AppBar>
                                <Toolbar>
                                    <IconButton onClick={() => onClose(null)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                        )}
                        <Stepper
                            fullScreen={fullScreen}
                            onCloseDialog={consultant => onClose(consultant)}
                            endCustomer={endCustomer}
                            invoiceBy={invoiceBy}
                            consultant={consultant}
                        />
                    </DialogContent>
                </_Dialog>
            </div>
        );
    }
}

export default withMobileDialog({ breakpoint: "xs" })(Dialog);
