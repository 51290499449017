// @flow

import style from "./style.module.scss";

import React, { PureComponent } from "react";
import classnames from "classnames";
import { TextField } from "@material-ui/core";
import helptexts from "../../../utils/helptexts";

export type Value = {
    center: string,
};

export type Error = {
    [key: string]: ?string,
};

type Props = {
    value: Value,
    error?: ?Error,
    onBlur: () => void,
    onChange: (value: Value) => void,
};

type State = {
    labelWidth: 0,
    touched: {
        center: boolean,
    },
};

/**
 * FreelanceGeneral
 */
export default class FreelanceGeneral extends PureComponent<Props, State> {
    // Initial state
    state = {
        labelWidth: 0,
        touched: {
            center: false,
        },
    };

    handleChange = (field: string) => (inputValue: *) => {
        const { value, onChange } = this.props;
        const { touched } = this.state;

        this.setState({
            touched: {
                ...touched,
                [field]: true,
            },
        });

        onChange({
            ...value,
            [field]: inputValue,
        });
    };

    hasError(field: string): boolean {
        return !!this.getError(field);
    }

    getError(field: string): ?string {
        const { error } = this.props;
        const { touched } = this.state;

        if (touched[field] && error && error[field]) {
            return error[field];
        }
    }

    handleblur = (field: string) => () => {
        const { touched } = this.state;
        const { onBlur } = this.props;

        this.setState(
            {
                touched: {
                    ...touched,
                    [field]: true,
                },
            },
            onBlur(),
        );
    };

    /**
     * Render
     */
    render() {
        const { value, onBlur } = this.props;

        return (
            <div className={classnames(style.wrapper)}>
                <TextField
                    id="outlined-competenceCenter"
                    label="Competence Center"
                    value={value.center}
                    onChange={event =>
                        this.handleChange("center")(event.target.value)
                    }
                    margin="dense"
                    variant="outlined"
                    onBlur={onBlur}
                    helperText={helptexts.competenceCenter.center}
                    fullWidth
                />
            </div>
        );
    }
}
