// @flow

import style from "./style.module.scss";

import React from "react";
import { Button, Typography } from "@material-ui/core";

type Props = {
    disabled: boolean,
    onChange: *,
    online: boolean,
};

const UploadInput = ({ disabled, onChange, online }: Props) => {
    console.log("disabled: ", disabled);
    return (
        <React.Fragment>
            <input
                accept="*"
                id="contained-button-file"
                type="file"
                className={style.fileinput}
                onChange={event => onChange(event.target.files)}
                multiple
            />
            {!online && (
                <Typography variant="body" color="secondary">
                    Je bent momenteel niet online. Bijlagen toevoegen is enkel
                    mogelijk wanneer je online bent.
                </Typography>
            )}
            <label htmlFor="contained-button-file">
                <Button
                    variant="contained"
                    component="span"
                    color="primary"
                    disabled={disabled || !online}
                    style={{
                        marginTop: "1em",
                    }}
                >
                    Bijlagen toevoegen
                </Button>
            </label>
        </React.Fragment>
    );
};

export default UploadInput;
