// @flow

// Import libs
import React, { PureComponent } from "react";
import { TextField, InputAdornment, MenuItem } from "@material-ui/core";
import classnames from "classnames";

// Import styling
import style from "./style.module.scss";

export type Value = {
    price: string,
    hours: number,
};

// Define props
type Props = {
    value: Value,
    onChange: (value: Value) => void,
    onBlur: () => void,
    error: ?string,
};

type State = {
    touched: boolean,
};

/**
 * A FixedPrice component
 */
export default class FixedPrice extends PureComponent<Props, State> {
    state = {
        touched: false,
    };

    handleChange(value: *) {
        const { onChange } = this.props;

        this.setState({
            touched: true,
        });

        onChange(value);
    }

    /**
     * Render
     */
    render() {
        const { value, error, onBlur } = this.props;
        const { touched } = this.state;

        return (
            <div>
                <div className={classnames(style.wrapper)}>
                    <div className={classnames(style.fieldwrapper)}>
                        <div className={classnames(style.field)}>
                            <TextField
                                id="outlined-select-hours"
                                select
                                label="Aantal uren / week"
                                value={value.hours}
                                onChange={event =>
                                    this.handleChange({
                                        ...value,
                                        hours: event.target.value,
                                    })
                                }
                                onBlur={onBlur}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                            >
                                <MenuItem value={38}>38 u/week</MenuItem>
                                <MenuItem value={40}>40 u/week</MenuItem>
                            </TextField>
                        </div>
                        <div className={classnames(style.field)}>
                            <TextField
                                id="outlined-name"
                                label="Prijs"
                                value={value.price}
                                type="number"
                                onChange={event =>
                                    this.handleChange({
                                        ...value,
                                        price: event.target.value,
                                    })
                                }
                                onBlur={onBlur}
                                error={touched && !!error}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            €
                                        </InputAdornment>
                                    ),
                                }}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
