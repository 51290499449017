// @flow

// Import styling
import style from "./style.module.scss";

// Import libs
import React, { PureComponent } from "react";
import classnames from "classnames";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { DatePicker } from "material-ui-pickers";
import moment from "moment";

// Define a milestone
export type Value = {
    price: string,
    date: number,
    description?: string,
};

export type Error = {
    price?: ?string,
    date?: ?string,
    description?: ?string,
};

// Define props
type Props = {
    value: Value,
    onDelete: () => void,
    onChange: (value: Value) => void,
    onBlur: () => void,
    error?: ?Error,
};

type State = {
    touched: {
        price: boolean,
        date: boolean,
        description: boolean,
    },
};

const DATE_FORMAT = "DD/MM/YYYY";

/**
 * A FixedPriceMilestones component
 */
export default class Milestone extends PureComponent<Props, State> {
    state = {
        touched: {
            price: false,
            date: false,
            description: false,
        },
    };

    /**
     * Change handler
     */
    handleChange = (field: *) => (inputValue: *) => {
        const { value: original, onChange } = this.props;
        const { touched } = this.state;

        this.setState({
            touched: {
                ...touched,
                [field]: true,
            },
        });

        onChange({
            ...original,
            [field]: inputValue,
        });
    };

    handleDateChange = (field: string) => (date: *) => {
        this.handleChange(field)(date.unix());
    };

    handleBlur = (field: string) => () => {
        const { touched } = this.state;
        const { onBlur } = this.props;
        this.setState(
            {
                touched: {
                    ...touched,
                    [field]: true,
                },
            },
            onBlur(),
        );
    };

    /**
     * Test if there is an error for the field
     */
    hasError(field: string): boolean {
        return !!this.getError(field);
    }

    /**
     * Get the error message by field name
     */
    getError(field: string): ?string {
        const { error } = this.props;
        const { touched } = this.state;

        if (error && error[field] && touched[field]) {
            return error[field];
        }
    }

    /**
     * Render
     */
    render() {
        const { onDelete, value, onBlur } = this.props;

        return (
            <div className={classnames(style.wrapper)}>
                <div className={classnames(style.fieldwrapper)}>
                    <div className={classnames(style.field)}>
                        <TextField
                            id="outlined-name"
                            label="Prijs"
                            value={value.price}
                            type="number"
                            onChange={event =>
                                this.handleChange("price")(event.target.value)
                            }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        €
                                    </InputAdornment>
                                ),
                            }}
                            onBlur={() => onBlur()}
                            error={this.hasError("price")}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    <div className={classnames(style.field)}>
                        <DatePicker
                            id="outlined-name"
                            label="Datum"
                            invalidLabel="dd/mm/yyyy"
                            value={value.date ? moment.unix(value.date) : null}
                            onChange={this.handleDateChange("date")}
                            format={DATE_FORMAT}
                            margin="dense"
                            variant="outlined"
                            onBlur={this.handleBlur("date")}
                            error={this.hasError("date")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            keyboard
                            disableOpenOnEnter
                            mask={value =>
                                value
                                    ? [
                                          /\d/,
                                          /\d/,
                                          "/",
                                          /\d/,
                                          /\d/,
                                          "/",
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                      ]
                                    : []
                            }
                            placeholder="dd/mm/jjjj"
                        />
                    </div>
                    <div className={classnames(style.field)}>
                        <TextField
                            id="outlined-name"
                            label="Omschrijving - optioneel"
                            value={value.description}
                            type="text"
                            onChange={event =>
                                this.handleChange("description")(
                                    event.target.value,
                                )
                            }
                            onBlur={() => onBlur()}
                            error={this.hasError("description")}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    <div className={classnames(style.button)}>
                        <IconButton
                            aria-label="Delete"
                            onClick={() => onDelete()}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
        );
    }
}
