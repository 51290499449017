// @flow

import AttachmentsList from "./AttachmentList";
import { attachmentsHelper } from "../../utils";
import UploadProgress from "./UploadProgress";
import UploadInput from "./UploadInput";

import React from "react";
import { ApolloConsumer } from "react-apollo";
import {
    CircularProgress,
    Grid,
    Snackbar,
    Typography,
} from "@material-ui/core";
import gql from "graphql-tag";

const GET_UPLOAD_INFO = gql`
    query uploadInfo($input: [String]) {
        uploadInfo {
            accessKey
            secretAccessKey
            sessionToken
            bucket
            region
        }
        attachmentsToUpload(input: $input)
    }
`;

type Props = {
    onChange: (value: string[]) => void,
    value: string[],
    onUploadChange: (value: boolean) => void,
    isOnline: boolean,
};

type State = {
    uploading: boolean,
    reading: boolean,
    message: string,
    files: UploadFile[],
    credentials: Credentials,
};

export type Credentials = {
    accessKey: string,
    secretAccessKey: string,
    sessionToken: string,
    bucket: string,
    region: string,
};

export type UploadFile = {
    file: *,
    uniqueName: string,
    fileKey: string,
};

const getInitialValues = () => ({
    uploading: false,
    reading: false,
    message: "",
    files: [],
    credentials: {},
});

class Attachments extends React.Component<Props, State> {
    state = getInitialValues();
    filesProgress = {};

    handleChange = (client: *) => (files: *) => {
        const filteredFilesArray = this.filterAlreadyAddedFiles(files);
        const uploadFiles = attachmentsHelper.transformFilesArrayToUploadFilesArray(
            filteredFilesArray,
        );
        const newFiles = this.state.files.concat(uploadFiles);
        this.setState({
            ...this.state,
            uploading: false,
            reading: true,
            files: newFiles,
        });
        this.handleUploadStart();
        this.uploadFiles(newFiles, client);
    };

    uploadFiles = (files: *, client: *) => {
        const namesPromises = files.map(file =>
            attachmentsHelper.getUniqueNameForFile(file.file),
        );

        Promise.all(namesPromises).then(uniqueNames => {
            this.setState({
                ...this.state,
                uploading: true,
            });
            const updatedFiles = attachmentsHelper.mapUniqueNamesToFiles(
                files,
                uniqueNames,
            );

            this.getUploadInformation(
                updatedFiles.map(file => file.uniqueName),
                client,
            )
                .then(({ data }) => {
                    const { attachmentsToUpload, uploadInfo } = data;
                    const {
                        filesForUpload,
                        existingFiles,
                    } = attachmentsHelper.prepareFilesForUpload(
                        updatedFiles,
                        attachmentsToUpload,
                    );

                    this.setState({
                        ...this.state,
                        reading: false,
                        files: filesForUpload,
                        credentials: uploadInfo,
                        uploading: filesForUpload.length > 0,
                    });
                    this.setExistingFilesAsProcessed(existingFiles);
                    this.props.onUploadChange(filesForUpload.length > 0);
                })
                .catch(() => {
                    this.setState({
                        ...this.state,
                        message:
                            "Er is een fout opgetreden. Gelieve het opnieuw te proberen.",
                    });
                });
        });
    };

    handleFileUploadFail = (uploadFile: *) => {
        this.setState({
            ...this.state,
            message: `Fout bij uploaden van bestand ${uploadFile.file.name}`,
        });
    };

    handleFileUploadComplete = (uploadFile: *) => {
        const filteredFiles = this.state.files.filter(
            file => file.uniqueName !== uploadFile.uniqueName,
        );
        this.setState({
            ...this.state,
            files: filteredFiles,
            uploading: filteredFiles.length > 0,
        });
        this.props.onUploadChange(filteredFiles.length > 0);
        this.props.onChange(this.props.value.concat(uploadFile.uniqueName));
    };

    handleUploadStart = () => {
        this.props.onUploadChange(true);
    };

    setExistingFilesAsProcessed = (files: *) => {
        const { value, onChange } = this.props;
        onChange(value.concat(files.map(file => file.uniqueName)));
    };

    filterAlreadyAddedFiles = (files: *): any[] => {
        const { value } = this.props;
        return Array.from(files).filter(
            file =>
                value.findIndex(
                    uniqueName =>
                        attachmentsHelper.removeMD5Prefix(uniqueName) ===
                        file.name,
                ) === -1,
        );
    };

    resetState = () => {
        this.setState(getInitialValues());
    };

    getUploadInformation = (uniqueFileNames: *, client: *): Promise<any> => {
        return client.query({
            fetchPolicy: "no-cache",
            query: GET_UPLOAD_INFO,
            // $FlowFixMe
            variables: { input: uniqueFileNames }, // flow bug https://github.com/flow-typed/flow-typed/issues/2233
        });
    };

    handleCloseSnackbar = () => {
        this.setState({
            ...this.state,
            message: "",
        });
    };

    handleDelete = (uniqueFileName: string) => {
        console.log("handledelete ", this.state, uniqueFileName);
        const { value, onChange } = this.props;

        onChange(
            value.filter(file => {
                console.log("file ", file);
                return file !== uniqueFileName;
            }),
        );
    };

    render() {
        const { files, credentials, reading } = this.state;
        const { value, isOnline } = this.props;
        return (
            <ApolloConsumer>
                {client => (
                    <div>
                        <Snackbar
                            open={this.state.message !== ""}
                            message={this.state.message}
                            onClose={() => this.handleCloseSnackbar()}
                            autoHideDuration={2000}
                        />
                        <AttachmentsList
                            attachments={value}
                            onDelete={this.handleDelete}
                        />
                        <Grid
                            container
                            alignItems="center"
                            style={{
                                paddingBottom: "1em",
                            }}
                        >
                            {credentials.sessionToken &&
                                files
                                    .filter(file => file.fileKey !== "")
                                    .map(file => {
                                        return (
                                            <UploadProgress
                                                key={file.uniqueName}
                                                attachment={file}
                                                credentials={credentials}
                                                onDone={
                                                    this
                                                        .handleFileUploadComplete
                                                }
                                                onError={
                                                    this.handleFileUploadFail
                                                }
                                            />
                                        );
                                    })}
                        </Grid>
                        {reading && (
                            <React.Fragment>
                                <CircularProgress variant="indeterminate" />
                                <Typography
                                    variant="caption"
                                    style={{
                                        marginLeft: "1em",
                                    }}
                                >
                                    Bestanden verwerken
                                </Typography>
                            </React.Fragment>
                        )}
                        <UploadInput
                            disabled={reading}
                            online={isOnline}
                            onChange={this.handleChange(client)}
                        />
                    </div>
                )}
            </ApolloConsumer>
        );
    }
}

export default Attachments;
