// @flow

import style from "./style.module.scss";

import React, { PureComponent } from "react";
import classnames from "classnames";
import {
    TextField,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    InputAdornment,
} from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";
import moment from "moment";
import helptexts from "../../../utils/helptexts";

export type Value = {
    function: string,
    begin: number,
    eind: number,
    dailyRate: string,
    clientLocation: string,
    noticePeriod: string,
    trailPeriod: string,
    termOfPayment: string,
};

export type Error = {
    [key: string]: ?string,
};

type Props = {
    value: Value,
    error?: ?Error,
    onBlur: () => void,
    onChange: (value: Value) => void,
};

type State = {
    touched: {
        function: boolean,
        begin: boolean,
        eind: boolean,
        dailyRate: boolean,
        clientLocation: boolean,
        noticePeriod: boolean,
        trailPeriod: boolean,
        termOfPayment: boolean,
    },
};

const DATE_FORMAT = "DD/MM/YYYY";

/**
 * FreelanceGeneral
 */
export default class FreelanceInfo extends PureComponent<Props, State> {
    // Initial state
    state = {
        touched: {
            function: false,
            begin: false,
            eind: false,
            dailyRate: false,
            clientLocation: false,
            noticePeriod: false,
            trailPeriod: false,
            termOfPayment: false,
        },
    };

    handleChange = (field: string) => (inputValue: *) => {
        const { value, onChange } = this.props;
        const { touched } = this.state;

        this.setState({
            touched: {
                ...touched,
                [field]: true,
            },
        });

        onChange({
            ...value,
            [field]: inputValue,
        });
    };

    hasError(field: string): boolean {
        return !!this.getError(field);
    }

    getError(field: string): ?string {
        const { error } = this.props;
        const { touched } = this.state;

        if (touched[field] && error && error[field]) {
            return error[field];
        }
    }

    handleDateChange = (field: string) => (date: *) => {
        const { touched } = this.state;
        this.setState(
            {
                touched: {
                    ...touched,
                    [field]: true,
                },
            },
            this.handleChange(field)(date.unix()),
        );
    };

    handleBlur = (field: string) => () => {
        const { touched } = this.state;
        const { onBlur } = this.props;

        this.setState(
            {
                touched: {
                    ...touched,
                    [field]: true,
                },
            },
            onBlur(),
        );
    };

    /**
     * Render
     */
    render() {
        const { value, onBlur } = this.props;

        return (
            <div className={classnames(style.wrapper)}>
                <TextField
                    id="outlined-function"
                    label="Functie/Profiel"
                    value={value.function}
                    onChange={event =>
                        this.handleChange("function")(event.target.value)
                    }
                    margin="dense"
                    variant="outlined"
                    helperText={helptexts.freelancer.function}
                    fullWidth
                />
                <div className={classnames(style.datewrapper)}>
                    <div className={classnames(style.date)}>
                        <DatePicker
                            id="outlined-name"
                            label="Begindatum"
                            value={
                                value.begin ? moment.unix(value.begin) : null
                            }
                            onChange={this.handleDateChange("begin")}
                            format={DATE_FORMAT}
                            margin="dense"
                            variant="outlined"
                            onBlur={this.handleBlur("begin")}
                            error={this.hasError("begin")}
                            helperText={helptexts.freelancer.beginDatum}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            keyboard
                            disableOpenOnEnter
                            mask={value =>
                                value
                                    ? [
                                          /\d/,
                                          /\d/,
                                          "/",
                                          /\d/,
                                          /\d/,
                                          "/",
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                      ]
                                    : []
                            }
                            placeholder="dd/mm/jjjj"
                        />
                    </div>
                    <div className={classnames(style.date)}>
                        <DatePicker
                            id="outlined-name"
                            label="Einddatum - optioneel"
                            invalidLabel="dd/mm/yyyy"
                            value={value.eind ? moment.unix(value.eind) : null}
                            onChange={this.handleDateChange("eind")}
                            format={DATE_FORMAT}
                            margin="dense"
                            variant="outlined"
                            onBlur={this.handleBlur("eind")}
                            error={this.hasError("eind")}
                            helperText={helptexts.freelancer.eindDatum}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            keyboard
                            disableOpenOnEnter
                            mask={value =>
                                value
                                    ? [
                                          /\d/,
                                          /\d/,
                                          "/",
                                          /\d/,
                                          /\d/,
                                          "/",
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                      ]
                                    : []
                            }
                            placeholder="dd/mm/jjjj"
                        />
                    </div>
                </div>
                <TextField
                    id="outlined-dailyRate"
                    label="Dagtarief"
                    value={value.dailyRate}
                    type="number"
                    onChange={event =>
                        this.handleChange("dailyRate")(event.target.value)
                    }
                    onBlur={onBlur}
                    error={this.hasError("dailyRate")}
                    helperText={helptexts.general.dailyRate}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">€</InputAdornment>
                        ),
                    }}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                />
                <TextField
                    id="outlined-clientLocation"
                    label="Klant/locatie"
                    value={value.clientLocation}
                    onChange={event =>
                        this.handleChange("clientLocation")(event.target.value)
                    }
                    margin="dense"
                    variant="outlined"
                    onBlur={onBlur}
                    error={this.hasError("clientLocation")}
                    helperText={helptexts.general.clientLocation}
                    fullWidth
                />
                <div className={classnames(style.namewrapper)}>
                    <div className={classnames(style.name)}>
                        <TextField
                            id="outlined-noticePeriod"
                            label="Opzegtermijn"
                            value={value.noticePeriod}
                            onChange={event =>
                                this.handleChange("noticePeriod")(
                                    event.target.value,
                                )
                            }
                            defaultValue="1 maand"
                            placeholder="1 maand"
                            margin="dense"
                            variant="outlined"
                            onBlur={onBlur}
                            helperText={helptexts.freelancer.noticePeriod}
                            fullWidth
                        />
                    </div>
                    <div className={classnames(style.name)}>
                        <TextField
                            id="outlined-trailPeriod"
                            label="Proefperiode"
                            value={value.trailPeriod}
                            onChange={event =>
                                this.handleChange("trailPeriod")(
                                    event.target.value,
                                )
                            }
                            defaultValue="1 maand"
                            placeholder="1 maand"
                            margin="dense"
                            variant="outlined"
                            onBlur={onBlur}
                            helperText={helptexts.freelancer.trailPeriod}
                            fullWidth
                        />
                    </div>
                </div>
                <div className={style.paymentwrapper}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">
                            Betalingstermijn
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            value={value.termOfPayment}
                            onChange={event => {
                                this.handleChange("termOfPayment")(
                                    event.target.value,
                                );
                            }}
                            onBlur={onBlur}
                        >
                            <FormControlLabel
                                value="60 dagen"
                                control={<Radio color="primary" />}
                                label="60 dagen"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="30 dagen"
                                control={<Radio color="primary" />}
                                label="30 dagen"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        );
    }
}
