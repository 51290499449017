// @flow

// Import styling
import style from "./style.module.scss";

// Import libs
import React from "react";
import classnames from "classnames";
import { TextField, MenuItem } from "@material-ui/core";

// import { DateInterval } from "../";

export type Value = {
    hours: 38 | 40,
};

// Define props
type Props = {
    value: Value,
    onChange: (value: Value) => void,
    onBlur: () => void,
};

/**
 * A TM component
 */
const TimeAndMaterial = ({ value, onChange, onBlur }: Props) => (
    <div>
        <div className={classnames(style.wrapper)}>
            <div className={classnames(style.fieldwrapper)}>
                <div className={classnames(style.field)}>
                    <TextField
                        id="outlined-select-hours"
                        select
                        label="Aantal uren / week"
                        value={value.hours}
                        onChange={event =>
                            onChange({
                                ...value,
                                hours: event.target.value,
                            })
                        }
                        onBlur={onBlur}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                    >
                        <MenuItem value={38}>38 u/week</MenuItem>
                        <MenuItem value={40}>40 u/week</MenuItem>
                    </TextField>
                </div>
            </div>
        </div>
    </div>
);

export default TimeAndMaterial;
