// @flow

import style from "./style.module.scss";

// Import libs
import React from "react";
import { TextField, Typography } from "@material-ui/core";
import classnames from "classnames";

// Define props
type Props = {
    value: string,
    onChange: (value: string) => void,
};

/**
 * A General component
 */
const Remarks = ({ value, onChange }: Props) => (
    <div>
        <Typography variant="h6" className={classnames(style.title)}>
            Opmerking(en)
        </Typography>
        <TextField
            id="outlined-name"
            label="Opmerking(en)"
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
            margin="dense"
            variant="outlined"
            multiline
            rowsMax={5}
            fullWidth
        />
    </div>
);

export default Remarks;
