// @flow

// Import style
import style from "./style.module.scss";

// Import libs
import set from "lodash.set";
import React, { Component, Fragment } from "react";
import classnames from "classnames";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import debounce from "lodash.debounce";
import {
    Typography,
    Paper,
    Button,
    Divider,
    CircularProgress,
    Snackbar,
} from "@material-ui/core";
import { navigate } from "gatsby";

import {
    General,
    Invoicing,
    Remarks,
    Consultants,
    Attachments,
    FreelanceGeneral,
    FreelanceInfo,
    FreelanceServiceProvider,
    FreelanceCompetenceCenter,
    Tax,
} from "../";
import { type Value as GeneralValue } from "../General";
import { type Value as InvoicingValue } from "../Invoicing";
import { type Value as ServiceProviderValue } from "../Freelance/serviceProvider";
import { type Value as CompetenceValue } from "../Freelance/CompetenceCenter";
import { type Value as FreelanceValue } from "../Freelance/General";
import { type Value as InfoValue } from "../Freelance/Info";
import { type Value as TaxValue } from "../Tax";
import { Invoicing as InvoicingTypes } from "../../validation/schema/invoicing";

import schema from "../../validation/schema";

type Profile = {
    name: string,
    email: string,
    company: string,
};

export type Fields = {
    general: GeneralValue,
    invoicing: InvoicingValue,
    tax: TaxValue,
    consultants: *,
    remarks: string,
    attachments: string[],
    competenceCenter: CompetenceValue,
    serviceProvider: ServiceProviderValue,
    freelance: FreelanceValue,
    freelanceInfo: InfoValue,
};

// Define props
type Props = {
    profile: ?Profile,
    freelance?: boolean,
};

// Define state
type State = {
    formKey: number,
    canSubmit: boolean,
    fields: Fields,
    errors: *,
    uploading: boolean,
    isOnline: boolean,
};

const DEBOUNCE_WAIT = 500;
const DEBOUNCE_MAX_WAIT = 1000;

const SEND_DATA = gql`
    mutation Send($input: SendInput!) {
        send(input: $input) {
            success
        }
    }
`;

const SEND_FREELANCE_DATA = gql`
    mutation Send($input: SendInput!) {
        freelance(input: $input) {
            success
        }
    }
`;

const getInitialValues = () => ({
    general: {
        ccMailadres: "",
        eindKlant: "",
        facturatiemail: "",
        facturatie: "",
        projectnaam: "",
        refr: "",
        eind: 0,
        begin: 0,
        sales: "",
        order: "nieuwe order",
        orderNummer: "",
    },
    tax: {
        bedrijfsvoorheffing: "",
        korteOmschrijving: "",
        startBv: 0,
        eindBv: 0,
    },
    invoicing: {
        type: 1,
        fixedPrice: {
            price: "",
            hours: 38,
        },
        milestones: [],
        timeAndMaterial: {
            hours: 38,
        },
        sheduled: {
            price: "",
            interval: 1,
            variable: false,
        },
    },
    consultants: [],
    remarks: "",
    attachments: [],
    competenceCenter: {
        center: "",
    },
    freelance: {
        name: "",
        firstName: "",
        phoneNumber: "",
        email: "",
        nationality: "",
        freelanceType: "",
    },
    freelanceInfo: {
        function: "",
        begin: 0,
        eind: 0,
        dailyRate: "",
        clientLocation: "",
        noticePeriod: "",
        trailPeriod: "",
        termOfPayment: "",
    },
    serviceProvider: {
        company: "",
        legalForm: "",
        address: "",
        btwNumber: "",
        manager: "",
    },
});

/**
 * A Header component
 */
export default class Form extends Component<Props, State> {
    state = {
        formKey: Date.now(),
        fields: getInitialValues(),
        errors: {},
        canSubmit: false,
        uploading: false,
        isOnline: true,
    };

    componentDidMount() {
        window.addEventListener("online", () => this.updateOnlineStatus(true));
        window.addEventListener("offline", () =>
            this.updateOnlineStatus(false),
        );
    }

    updateOnlineStatus = (online: boolean) => {
        this.setState({
            ...this.state,
            isOnline: online,
        });
    };

    /**
     * Change handler
     */
    handleChange = (field: string) => (value: *) => {
        const { fields } = this.state;

        this.setState(
            {
                fields: {
                    ...fields,
                    [field]: value,
                },
            },
            () => this.validate(),
        );
    };

    handleUploadStateChange = (uploading: boolean) => {
        this.setState({
            ...this.state,
            uploading: uploading,
        });
    };

    /**
     * Blur handler
     */
    handleBlur() {
        this.validate();
    }

    /**
     * Reset form to initial state
     */
    reset() {
        this.setState({
            fields: getInitialValues(),
            errors: {},
            formKey: Date.now(),
            canSubmit: false,
        });
    }

    /**
     * Validate the current state of the form
     *
     * Populate the error object for consumption
     * Set the canSubmit state accordingly
     */
    validate = debounce(
        () => {
            const { fields } = this.state;
            schema
                .validate(fields, { abortEarly: false })
                .then(() =>
                    this.setState({
                        errors: {},
                        canSubmit: true,
                    }),
                )
                .catch(errors => {
                    const result = {};

                    errors.inner.forEach(({ path, message }) =>
                        set(result, path, message),
                    );

                    this.setState({
                        canSubmit: true,
                        errors: result,
                    });
                });
        },
        DEBOUNCE_WAIT,
        {
            leading: false,
            maxWait: DEBOUNCE_MAX_WAIT,
            trailing: true,
        },
    );

    /**
     * Render
     */
    render() {
        const { profile, freelance } = this.props;
        const {
            canSubmit,
            fields,
            errors,
            formKey,
            uploading,
            isOnline,
        } = this.state;

        if (!profile) {
            return <CircularProgress />;
        }

        return (
            <Mutation mutation={freelance ? SEND_FREELANCE_DATA : SEND_DATA}>
                {(mutate, { loading, data, error }) => (
                    <Paper className={style.wrapper}>
                        <Snackbar
                            open={!!error}
                            message="Er ging iets mis, gelieve het opnieuw te proberen."
                        />
                        {!freelance ? (
                            <Typography variant="h5">
                                Aanvraagformulier voor timesheet codes
                            </Typography>
                        ) : (
                            <Typography variant="h5">
                                Aanvraagformulier voor freelance contract
                            </Typography>
                        )}
                        <Divider className={style.divider} />
                        <div>
                            <form
                                key={formKey}
                                noValidate
                                autoComplete="off"
                                onSubmit={event => {
                                    event.preventDefault();
                                    console.log("data", data);
                                    mutate({
                                        variables: { input: fields },
                                    }).then(() =>
                                        navigate("/confirmation", {
                                            state: {
                                                profile,
                                                fields,
                                                freelance,
                                            },
                                        }),
                                    );
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    className={style.title}
                                >
                                    Algemeen
                                </Typography>
                                <General
                                    onChange={this.handleChange("general")}
                                    onBlur={this.handleBlur.bind(this)}
                                    aanvrager={profile.name}
                                    mailadresAanvrager={profile.email}
                                    value={fields.general}
                                    error={errors.general}
                                    freelance={freelance}
                                />
                                {freelance && (
                                    <Fragment>
                                        <Divider
                                            className={classnames(
                                                style.divider,
                                            )}
                                        />
                                        <Typography
                                            variant="h6"
                                            className={style.title}
                                        >
                                            Competence Center
                                        </Typography>
                                        <FreelanceCompetenceCenter
                                            onChange={this.handleChange(
                                                "competenceCenter",
                                            )}
                                            onBlur={this.handleBlur.bind(this)}
                                            value={fields.competenceCenter}
                                            error={errors.competenceCenter}
                                        />
                                        <Divider
                                            className={classnames(
                                                style.divider,
                                            )}
                                        />
                                        <Typography
                                            variant="h6"
                                            className={style.title}
                                        >
                                            Dienstverlener
                                        </Typography>
                                        <FreelanceServiceProvider
                                            onChange={this.handleChange(
                                                "serviceProvider",
                                            )}
                                            onBlur={this.handleBlur.bind(this)}
                                            value={fields.serviceProvider}
                                            error={errors.serviceProvider}
                                        />
                                        <Divider
                                            className={classnames(
                                                style.divider,
                                            )}
                                        />
                                        <Typography
                                            variant="h6"
                                            className={style.title}
                                        >
                                            Freelancer
                                        </Typography>
                                        <FreelanceGeneral
                                            onChange={this.handleChange(
                                                "freelance",
                                            )}
                                            onBlur={this.handleBlur.bind(this)}
                                            value={fields.freelance}
                                            error={errors.freelance}
                                        />
                                        <Divider
                                            className={classnames(
                                                style.divider,
                                            )}
                                        />
                                        <Typography
                                            variant="h6"
                                            className={style.title}
                                        >
                                            Informatie
                                        </Typography>
                                        <FreelanceInfo
                                            onChange={this.handleChange(
                                                "freelanceInfo",
                                            )}
                                            onBlur={this.handleBlur.bind(this)}
                                            value={fields.freelanceInfo}
                                            error={errors.freelanceInfo}
                                        />
                                    </Fragment>
                                )}
                                {!freelance && (
                                    <Fragment>
                                        <Divider
                                            className={classnames(
                                                style.divider,
                                            )}
                                        />
                                        <Tax
                                            value={fields.tax}
                                            error={errors.tax}
                                            onChange={this.handleChange("tax")}
                                            onBlur={this.handleBlur.bind(this)}
                                        />
                                    </Fragment>
                                )}
                                {!freelance && (
                                    <Invoicing
                                        value={fields.invoicing}
                                        error={errors.invoicing}
                                        onChange={this.handleChange(
                                            "invoicing",
                                        )}
                                        onBlur={this.handleBlur.bind(this)}
                                    />
                                )}
                                {!freelance &&
                                    fields.invoicing.type !==
                                        InvoicingTypes.SHEDULED && (
                                        <Fragment>
                                            <Typography
                                                variant="h6"
                                                className={classnames(
                                                    style.title,
                                                )}
                                            >
                                                <Divider
                                                    className={classnames(
                                                        style.divider,
                                                    )}
                                                />
                                                Consultants / Profielen
                                            </Typography>
                                            <Typography>
                                                Voeg hier de consultants /
                                                profielen toe die een timesheet
                                                code dienen te verkrijgen.
                                            </Typography>
                                            <Consultants
                                                value={fields.consultants}
                                                error={errors.consultants}
                                                onChange={this.handleChange(
                                                    "consultants",
                                                )}
                                                onBlur={this.handleBlur.bind(
                                                    this,
                                                )}
                                                endCustomer={
                                                    fields.general.eindKlant
                                                }
                                                invoiceBy={
                                                    fields.general.facturatie
                                                }
                                            />
                                        </Fragment>
                                    )}
                                <Divider
                                    className={classnames(style.divider)}
                                />
                                <Remarks
                                    value={fields.remarks}
                                    onChange={this.handleChange("remarks")}
                                />
                                <Typography
                                    variant="h6"
                                    className={classnames(style.title)}
                                >
                                    <Divider
                                        className={classnames(style.divider)}
                                    />
                                    Bijlagen
                                </Typography>
                                <Attachments
                                    value={fields.attachments}
                                    onChange={this.handleChange("attachments")}
                                    onUploadChange={
                                        this.handleUploadStateChange
                                    }
                                    isOnline={isOnline}
                                />
                                <div className={style.bar}>
                                    <Button
                                        id="cancel"
                                        variant="contained"
                                        className={style.button}
                                        onClick={this.reset.bind(this)}
                                        disabled={loading}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        id="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            !canSubmit ||
                                            loading ||
                                            uploading ||
                                            !isOnline
                                        }
                                        className={style.button}
                                        type="submit"
                                    >
                                        {(loading && (
                                            <Fragment>
                                                {"Sending "}
                                                <CircularProgress size={16} />
                                            </Fragment>
                                        )) ||
                                            "Submit"}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Paper>
                )}
            </Mutation>
        );
    }
}
