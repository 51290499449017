// @flow

// Import styling
import style from "./style.module.scss";

// Import libs
import React, { PureComponent, Fragment } from "react";
import classnames from "classnames";
import {
    TextField,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from "@material-ui/core";

import { DatePicker } from "material-ui-pickers";
import moment from "moment";
import helptexts from "../../utils/helptexts";

export type Value = {
    ccMailadres: string,
    eindKlant: string,
    facturatiemail: string,
    facturatie: string,
    projectnaam: string,
    refr: string,
    eind: number,
    begin: number,
    sales: string,
    order: string,
    orderNummer: string,
};

export type Error = {
    [key: string]: ?string,
};

// Define props
type Props = {
    value: Value,
    error?: ?Error,
    onChange: (value: Value) => void,
    onBlur: () => void,
    aanvrager: string,
    mailadresAanvrager: string,
    freelance?: boolean,
};

type State = {
    touched: {
        ccMailadres: boolean,
        eindKlant: boolean,
        facturatie: boolean,
        projectnaam: boolean,
        refr: boolean,
        eind: boolean,
        begin: boolean,
        sales: boolean,
        order: boolean,
        orderNummer: boolean,
    },
};

const DATE_FORMAT = "DD/MM/YYYY";

/**
 * A General component
 */
export default class General extends PureComponent<Props, State> {
    // State
    state = {
        touched: {
            ccMailadres: false,
            eindKlant: false,
            facturatie: false,
            projectnaam: false,
            refr: false,
            eind: false,
            begin: false,
            sales: false,
            order: false,
            orderNummer: false,
        },
    };

    /**
     * Change handler
     */
    handleChange = (field: string) => (inputValue: *) => {
        const { value, onChange } = this.props;
        const { touched } = this.state;

        this.setState({
            touched: {
                ...touched,
                [field]: true,
            },
        });

        onChange({
            ...value,
            [field]: inputValue,
        });
    };

    /**
     * Test if there is an error for this field
     */
    hasError(field: string): boolean {
        return !!this.getError(field);
    }

    /**
     * Get the error message for a field
     */
    getError(field: string): ?string {
        const { error } = this.props;
        const { touched } = this.state;

        if (touched[field] && error && error[field]) {
            return error[field];
        }
    }

    handleDateChange = (field: string) => (date: *) => {
        const { touched } = this.state;
        this.setState(
            {
                touched: {
                    ...touched,
                    [field]: true,
                },
            },
            this.handleChange(field)(date.unix()),
        );
    };

    handleBlur = (field: string) => () => {
        const { touched } = this.state;
        const { onBlur } = this.props;
        this.setState(
            {
                touched: {
                    ...touched,
                    [field]: true,
                },
            },
            onBlur(),
        );
    };

    /**
     * Render
     */
    render() {
        const {
            aanvrager,
            mailadresAanvrager,
            value,
            freelance,
            onBlur,
        } = this.props;

        return (
            <div className={classnames(style.wrapper)}>
                <TextField
                    id="outlined-name"
                    label="Aanvrager"
                    value={aanvrager}
                    margin="dense"
                    variant="outlined"
                    disabled
                    fullWidth
                />
                <TextField
                    id="outlined-name"
                    label="Mailadres Aanvrager"
                    value={mailadresAanvrager}
                    margin="dense"
                    variant="outlined"
                    disabled
                    fullWidth
                />
                <TextField
                    id="outlined-name"
                    label="CC Mailadres"
                    value={value.ccMailadres}
                    margin="dense"
                    variant="outlined"
                    onChange={event =>
                        this.handleChange("ccMailadres")(event.target.value)
                    }
                    onBlur={onBlur}
                    error={this.hasError("ccMailadres")}
                    helperText={helptexts.general.ccMailadres}
                    fullWidth
                />
                {!freelance && (
                    <Fragment>
                        <TextField
                            id="outlined-name"
                            label="EindKlant"
                            value={value.eindKlant}
                            onChange={event =>
                                this.handleChange("eindKlant")(
                                    event.target.value,
                                )
                            }
                            margin="dense"
                            variant="outlined"
                            onBlur={onBlur}
                            error={this.hasError("eindKlant")}
                            helperText={helptexts.general.eindKlant}
                            fullWidth
                        />
                        <TextField
                            id="outlined-name"
                            label="Email facturatiedienst klant"
                            value={value.facturatiemail}
                            onChange={event =>
                                this.handleChange("facturatiemail")(
                                    event.target.value,
                                )
                            }
                            margin="dense"
                            variant="outlined"
                            onBlur={onBlur}
                            error={this.hasError("facturatiemail")}
                            helperText={helptexts.general.facturatiemail}
                            fullWidth
                        />
                        <TextField
                            id="outlined-name"
                            label="Facturatie aan eindklant door ?"
                            value={value.facturatie}
                            onChange={event =>
                                this.handleChange("facturatie")(
                                    event.target.value,
                                )
                            }
                            margin="dense"
                            variant="outlined"
                            onBlur={onBlur}
                            error={this.hasError("facturatie")}
                            helperText={helptexts.general.facturatie}
                            fullWidth
                        />
                        <TextField
                            id="outlined-name"
                            label="Sales / Accountmanager - optioneel"
                            value={value.sales}
                            onChange={event =>
                                this.handleChange("sales")(event.target.value)
                            }
                            margin="dense"
                            variant="outlined"
                            onBlur={onBlur}
                            error={this.hasError("sales")}
                            helperText={helptexts.general.sales}
                            fullWidth
                        />
                        <TextField
                            id="outlined-name"
                            label="Projectnaam - optioneel"
                            value={value.projectnaam}
                            onChange={event =>
                                this.handleChange("projectnaam")(
                                    event.target.value,
                                )
                            }
                            margin="dense"
                            variant="outlined"
                            onBlur={onBlur}
                            error={this.hasError("projectnaam")}
                            helperText={helptexts.general.projectNaam}
                            fullWidth
                        />
                        <div className={style.orderWrapper}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Order</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="position"
                                    name="position"
                                    value={value.order}
                                    onChange={event => {
                                        this.handleChange("order")(
                                            event.target.value,
                                        );
                                    }}
                                    onBlur={onBlur}
                                    error={this.hasError("order")}
                                >
                                    <FormControlLabel
                                        value="nieuwe order"
                                        control={<Radio color="primary" />}
                                        label="Nieuwe order"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="bestaande order"
                                        control={<Radio color="primary" />}
                                        label="Bestaande order"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>
                            {value.order == "bestaande order" && (
                                <div className={classnames(style.itemwrapper)}>
                                    <div className={classnames(style.item)}>
                                        <TextField
                                            id="outlined-orderNummer"
                                            label="Ordernummer"
                                            value={value.orderNummer}
                                            onChange={event =>
                                                this.handleChange(
                                                    "orderNummer",
                                                )(event.target.value)
                                            }
                                            margin="dense"
                                            variant="outlined"
                                            onBlur={onBlur}
                                            error={this.hasError("orderNummer")}
                                            helperText={
                                                helptexts.general.orderNummer
                                            }
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <TextField
                            id="outlined-name"
                            label="Ref /PO nr klant - optioneel"
                            value={value.refr}
                            onChange={event =>
                                this.handleChange("refr")(event.target.value)
                            }
                            margin="dense"
                            onBlur={onBlur}
                            error={this.hasError("refr")}
                            helperText={helptexts.general.refr}
                            variant="outlined"
                            fullWidth
                        />

                        <div className={classnames(style.datewrapper)}>
                            <div className={classnames(style.date)}>
                                <DatePicker
                                    id="outlined-name"
                                    label="Begindatum"
                                    value={
                                        value.begin
                                            ? moment.unix(value.begin)
                                            : null
                                    }
                                    onChange={this.handleDateChange("begin")}
                                    format={DATE_FORMAT}
                                    margin="dense"
                                    variant="outlined"
                                    onBlur={this.handleBlur("begin")}
                                    error={this.hasError("begin")}
                                    helperText={helptexts.general.beginDatum}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    keyboard
                                    disableOpenOnEnter
                                    mask={value =>
                                        value
                                            ? [
                                                  /\d/,
                                                  /\d/,
                                                  "/",
                                                  /\d/,
                                                  /\d/,
                                                  "/",
                                                  /\d/,
                                                  /\d/,
                                                  /\d/,
                                                  /\d/,
                                              ]
                                            : []
                                    }
                                    placeholder="dd/mm/jjjj"
                                />
                            </div>
                            <div className={classnames(style.date)}>
                                <DatePicker
                                    id="outlined-name"
                                    label="Einddatum - optioneel"
                                    invalidLabel="dd/mm/yyyy"
                                    value={
                                        value.eind
                                            ? moment.unix(value.eind)
                                            : null
                                    }
                                    onChange={this.handleDateChange("eind")}
                                    format={DATE_FORMAT}
                                    margin="dense"
                                    variant="outlined"
                                    onBlur={this.handleBlur("eind")}
                                    error={this.hasError("eind")}
                                    helperText={helptexts.general.eindDatum}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    keyboard
                                    disableOpenOnEnter
                                    mask={value =>
                                        value
                                            ? [
                                                  /\d/,
                                                  /\d/,
                                                  "/",
                                                  /\d/,
                                                  /\d/,
                                                  "/",
                                                  /\d/,
                                                  /\d/,
                                                  /\d/,
                                                  /\d/,
                                              ]
                                            : []
                                    }
                                    placeholder="dd/mm/jjjj"
                                />
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        );
    }
}
