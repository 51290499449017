import * as yup from "yup";

const MSG_REQUIRED = "Dit is een verplicht veld";

const InvoiceStep = yup.object().shape({
    step: yup.number(),
    value: yup.string().required(),
    custom: yup.boolean(),
});

export default yup.object().shape({
    consultant: yup.string().required(MSG_REQUIRED),
    aantalDagen: yup.string(),
    getekend: yup.boolean(),
    salesRate: yup.string().required(MSG_REQUIRED),
    competenceCenter: yup.string().required(MSG_REQUIRED),
    facturatie: yup.array().of(InvoiceStep),
});
