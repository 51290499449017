// @flow

import React, { type Node } from "react";
import { ApolloProvider } from "react-apollo";

import { apiClient } from "../../utils";

type Props = {
    children: Node,
};

/**
 * Provides a configured api client into context
 */
const ApiProvider = ({ children }: Props) => (
    <ApolloProvider client={apiClient}>{children}</ApolloProvider>
);

export const wrapper = ({ element }: *) => <ApiProvider>{element}</ApiProvider>;

export default ApiProvider;
