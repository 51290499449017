// @flow

// Import styling
// import style from "./style.module.scss";

// Import libs
import React, { Component } from "react";

import { Button, Typography } from "@material-ui/core";

import Dialog from "./Dialog";
import { type InvoiceStep } from "./Stepper/Steps/Invoicing";
import ConsultantList from "./List/ConsultantList";

export type ConsultantType = {
    consultant: string,
    aantalDagen: string,
    getekend: boolean,
    salesRate: string,
    competenceCenter: string,
    facturatie: InvoiceStep[],
};

// Define props
type PropsType = {
    value: ConsultantType[],
    error?: ?string,
    endCustomer: string,
    invoiceBy: string,
    onChange: (value: ConsultantType[]) => void,
    onBlur?: () => void,
};

// Define state
type StateType = {
    open: boolean,
    touched: boolean,
    consultant?: ConsultantType,
    consultantIndex?: number,
};

/**
 * A Consultant component
 */
export default class Consultants extends Component<PropsType, StateType> {
    state = {
        open: false,
        touched: false,
        consultant: undefined,
        consultantIndex: -1,
    };

    /**
     * Deletes a consultant by index
     */
    handleDelete = (index: number) => {
        const { value, onChange } = this.props;

        this.setState({
            touched: true,
        });

        onChange(value.filter((_, current) => current != index));
    };

    /**
     * Handle dialog close
     */
    handleClose(consultant: ?ConsultantType) {
        const { value, onChange } = this.props;
        const { consultantIndex } = this.state;

        if (consultant) {
            if (consultantIndex === -1) {
                onChange([...value, consultant]);
            } else {
                // consultant has been selected for editing
                if (value) {
                    onChange(
                        value.map((consultantEntry, index) => {
                            if (consultantIndex === index && consultant) {
                                // return the edited object
                                return consultant;
                            }
                            return consultantEntry;
                        }),
                    );
                }
            }
        }

        this.setState({
            open: false,
            touched: true,
            consultant: undefined,
            consultantIndex: -1,
        });
    }

    handleEdit = (index: number) => {
        const { value } = this.props;
        const consultant = value[index];

        this.setState({
            open: true,
            touched: true,
            consultant,
            consultantIndex: index,
        });
    };

    /**
     * Render
     */
    render() {
        const { open, touched, consultant } = this.state;
        const { value, error, endCustomer, invoiceBy } = this.props;

        return (
            <div>
                {error && touched && (
                    <Typography color="error">{error}</Typography>
                )}
                <Dialog
                    open={open}
                    onClose={consultant => this.handleClose(consultant)}
                    endCustomer={endCustomer}
                    invoiceBy={invoiceBy}
                    consultant={consultant}
                />

                <ConsultantList
                    value={value}
                    endCustomer={endCustomer}
                    invoiceBy={invoiceBy}
                    onEdit={this.handleEdit}
                    onDelete={this.handleDelete}
                    editable
                />

                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        this.setState({
                            open: true,
                        });
                    }}
                >
                    Consultant of Profiel toevoegen
                </Button>
            </div>
        );
    }
}
