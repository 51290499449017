// @flow

import qs from "qs";
import gql from "graphql-tag";

import { apiClient } from "../";

type TokenData = {
    aud: string,
    iss: string,
    iat: number,
    exp: number,
    sub: string,
    name: string,
    email: string,
    company: string,
};

const AUTH_ERROR_MSG = "Could not login!";

const LOGIN_QUERY = gql`
    query login($grant: String!, $redirectUrl: String!) {
        login(grant: $grant, redirectUrl: $redirectUrl) {
            token
        }
    }
`;

let grant = null;
let token = null;
let maxRetriesReached = false;

const notifiers = [];

/**
 * Add notification promise and return
 */
const createNotifier = () =>
    new Promise(resolve => notifiers.push({ resolve }));

/**
 * Resolve all pending notifiers
 */
const resolveNotifiers = (data: TokenData) => {
    while (notifiers.length) {
        notifiers.shift().resolve(data);
    }
};

/**
 * Decode the token
 */
const decode = (): ?TokenData => {
    if (token && token.split(".")[1]) {
        return JSON.parse(atob(token.split(".")[1]));
    }
};

/**
 * Test if a token is present and valid
 */
const valid = (): boolean => {
    const data = decode();

    if (data && parseInt(data.exp) > Math.floor(Date.now() / 1000)) {
        return true;
    }

    return false;
};

/**
 * Parse for code and store in local storage
 */
export const parse = () => {
    const { code, error } = qs.parse(location.search.substr(1));

    maxRetriesReached = !!error;

    if (code) {
        // Store the grant
        grant = code;

        // Clear the grant from the uri
        history.replaceState(
            null,
            document.title,
            `${location.origin}${location.pathname}`,
        );
    }
};

/**
 * Get a possible token
 */
export const getToken = () => token;

/**
 * Read the token data
 */
export const getProfile = () => valid() && decode();

/**
 * Log out
 */
export const logout = () => {
    // Clear grant and token
    grant = null;
    token = null;

    // Redirect to login
    if (!maxRetriesReached) {
        location.href = `${process.env.GATSBY_AZURE_AD_URL}?response_type=code&client_id=${process.env.GATSBY_AZURE_AD_CLIENT_ID}&scope=${process.env.GATSBY_AZURE_AD_SCOPE}&redirect_uri=${location.href}`;
    }
};

/**
 * Fetch the token from server
 */
const fetchToken = () => {
    const redirectUrl = `${location.origin}${location.pathname}`;

    // Clear grant cache
    const variables = {
        grant,
        redirectUrl,
    };

    grant = null;

    return apiClient
        .query({
            query: LOGIN_QUERY,
            variables,
        })
        .then(({ data }) => {
            // Store our new token, validate later
            token = data?.login?.token;

            // Token validity check
            if (valid()) {
                // Resolve promise with token
                resolveNotifiers((decode(): any));
            } else {
                throw new Error(AUTH_ERROR_MSG);
            }
        })
        .catch(logout);
};

/**
 * Return a promise with valid token data, or redirect to login page
 */
export const login = (): Promise<TokenData> => {
    // First check if a token is in cache and valid and not loading
    const cached = !notifiers.length && getProfile();
    if (cached) {
        // We have a valid token in store, resolve to it
        return Promise.resolve(cached);
    }

    // Try to log in with cached grant
    if (grant || notifiers.length) {
        if (!notifiers.length) {
            fetchToken();
        }
        return createNotifier();
    }

    // We are not logged in at all. Redirect...
    logout();

    // End with a rejection
    return Promise.reject(AUTH_ERROR_MSG);
};
