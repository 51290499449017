// @flow

import CryptoJS from "crypto-js";
import aws from "aws-sdk";

import { type UploadFile, type Credentials } from "../components/Attachments";

/**
 * Get unique filename by append MD5 hash in front of filename
 * @param {*} file - file for which to generated unique name
 * @param {*} fileName - original file name
 */
export const getUniqueNameForFile = (file: *): Promise<string> => {
    return new Promise((resolve, reject) => {
        getMD5(file)
            .then(hash => resolve(`${hash}_${file.name}`))
            .catch(err => reject(err));
    });
};

/**
 * removes the MD5 portion of the file name for display purposes
 * @param {string} fileName - unique filename
 */
export const removeMD5Prefix = (fileName: string): string => {
    const parts = fileName.split("_");
    parts.splice(0, 1);
    return parts.join("_");
};

/**
 * Upload a file to S3
 * @param {File} file - File object received from fileInput
 * @param {Object} uploadInfo - information needed to perform upload to S3 Bucket
 */
export const uploadSingleFileToS3 = (
    file: UploadFile,
    uploadInfo: Credentials,
    progressCallBack: * = null,
): Promise<any> => {
    const s3Client = new aws.S3({
        accessKeyId: uploadInfo.accessKey,
        secretAccessKey: uploadInfo.secretAccessKey,
        sessionToken: uploadInfo.sessionToken,
        region: uploadInfo.region,
    });
    const managedUpload = s3Client
        .upload({
            Body: file.file,
            Bucket: uploadInfo.bucket,
            Key: file.fileKey,
        })
        .on("httpUploadProgress", progressCallBack);
    return managedUpload.promise();
};

/**
 * Maps the filekeys from the array to the corresponding file in the files array
 * @param {UploadFile[]} files - Array of files for upload
 * @param {Array} fileKeys - Array of fileKeys generated by API
 */
export const mapFileKeysToFiles = (
    files: UploadFile[],
    fileKeys: *,
): UploadFile[] => {
    return files.map(file => {
        const fileKey = fileKeys.find(x => x.includes(file.uniqueName));
        file.fileKey = fileKey;
        return file;
    });
};

/**
 * Extract only the files from the userselection that still to be uploaded based on the response from the API
 * @param {UploadFile[]} files - Array of files from the userselection
 * @param {string[]} objectKeys - Array of filekeys generated by the API
 */
export const extractAttachmentsToUpload = (
    files: UploadFile[],
    objectKeys: *,
): UploadFile[] => {
    if (objectKeys) {
        const strippedObjectKeys = objectKeys.map(objk => objk.split("/")[1]);
        return files.filter(file => {
            // only return file when a matching objectkey is found for it
            return strippedObjectKeys.includes(file.uniqueName);
        });
    }
    return [];
};

/**
 * Extract the attachments from the userselection that already exist on the storage
 * @param {UploadFile[]} files - Array of files from the userselection
 * @param {string[]} objectKeys - Array of filekeys generated by the API
 */
export const determineAlreadyExistingAttachments = (
    files: UploadFile[],
    objectKeys: *,
): UploadFile[] => {
    if (objectKeys) {
        const strippedObjectKeys = objectKeys.map(objk => objk.split("/")[1]);
        return files.filter(file => {
            // only return file when a matching objectkey is found for it
            return !strippedObjectKeys.includes(file.uniqueName);
        });
    }
    return [];
};

/**
 * Transforms the FileList from the input field to a usable format
 * @param {FileList} files - List of files selected by the user to upload
 */
export const transformFilesArrayToUploadFilesArray = (
    files: *[],
): UploadFile[] => {
    return files.map(file => ({
        file,
        uniqueName: "",
        fileKey: "",
    }));
};

/**
 * Puts the corresponding unique filename on each of the objects in the files array
 * @param {UploadFile[]} files - Array of files from the userselection
 * @param {string[]} uniqueNames - Array of unique filenames
 */
export const mapUniqueNamesToFiles = (
    files: UploadFile[],
    uniqueNames: string[],
): UploadFile[] => {
    const updatedFiles = files.concat();
    updatedFiles.forEach(updFile => {
        const uniqueName = uniqueNames.find(x => x.includes(updFile.file.name));
        updFile.uniqueName = uniqueName || "";
    });

    return updatedFiles;
};

/**
 * Processes the result from the UploadInfo query
 * @param {*} files - Array of files from the userselection
 * @param {*} attachmentsToUpload - array of string with filenames from API
 */
export const prepareFilesForUpload = (
    files: UploadFile[],
    attachmentsToUpload: *,
) => {
    return {
        filesForUpload: mapFileKeysToFiles(
            extractAttachmentsToUpload(files, attachmentsToUpload),
            attachmentsToUpload,
        ),
        existingFiles: determineAlreadyExistingAttachments(
            files,
            attachmentsToUpload,
        ),
    };
};

function readChunked(file, chunkCallback, endCallback) {
    var fileSize = file.size;
    var chunkSize = 4 * 1024 * 1024; // 4MB
    var offset = 0;

    var reader = new FileReader();
    reader.onload = function() {
        if (reader.error) {
            endCallback(reader.error || {});
            return;
        }
        // $FlowFixMe
        offset += reader.result.length;
        chunkCallback(reader.result, offset, fileSize);
        if (offset >= fileSize) {
            endCallback(null);
            return;
        }
        readNext();
    };

    reader.onerror = function(err) {
        endCallback(err || {});
    };

    function readNext() {
        var fileSlice = file.slice(offset, offset + chunkSize);
        reader.readAsBinaryString(fileSlice);
    }
    readNext();
}

function getMD5(blob, cbProgress) {
    return new Promise((resolve, reject) => {
        var md5 = CryptoJS.algo.MD5.create();
        readChunked(
            blob,
            (chunk, offs, total) => {
                md5.update(CryptoJS.enc.Latin1.parse(chunk));
                if (cbProgress) {
                    cbProgress(offs / total);
                }
            },
            err => {
                if (err) {
                    reject(err);
                } else {
                    var hash = md5.finalize();
                    var hashHex = hash.toString(CryptoJS.enc.Hex);
                    resolve(hashHex);
                }
            },
        );
    });
}
