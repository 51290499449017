// @flow

import React from "react";

import {
    Hidden,
    Grid,
    Card,
    CardHeader,
    Avatar,
    CardContent,
    Typography,
    CardActions,
    IconButton,
    Paper,
    Divider,
    Checkbox,
} from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";

import style from "./style.module.scss";
import { type ConsultantType } from "../index";
import Facturatie from "../Facturatie";

type propsType = {
    value: ConsultantType[],
    endCustomer: string,
    invoiceBy: string,
    editable: boolean,
    onEdit?: *,
    onDelete?: *,
};

const getInitials = (name: string) => {
    return name
        .split(" ")
        .map(part => part[0].toUpperCase())
        .join("");
};

const ConsultantList = (props: propsType) => {
    const { value, endCustomer, invoiceBy, editable, onEdit, onDelete } = props;
    return (
        <React.Fragment>
            <Hidden smUp>
                <Grid container direction="column">
                    {value.map((consultant, index) => {
                        return (
                            <Card key={index} className={style.consultantcard}>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            aria-label="Consultant"
                                            className={style.consultantavatar}
                                        >
                                            {getInitials(consultant.consultant)}
                                        </Avatar>
                                    }
                                    color="orange"
                                    title={consultant.consultant}
                                    subheader={consultant.competenceCenter}
                                />
                                <CardContent>
                                    <Grid container direction="column">
                                        <Grid container alignItems="center">
                                            <Grid item xs={6}>
                                                <Typography variant="body2">
                                                    Aantal dagen
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="caption">
                                                    {(consultant.aantalDagen &&
                                                        consultant.aantalDagen
                                                            .trim &&
                                                        consultant.aantalDagen.trim()
                                                            .length &&
                                                        consultant.aantalDagen) ||
                                                        "--"}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container alignItems="center">
                                            <Grid item xs={6}>
                                                <Typography variant="body2">
                                                    Sales rate
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="caption">
                                                    €{consultant.salesRate}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container alignItems="center">
                                            <Grid item xs={6}>
                                                <Typography variant="body2">
                                                    Getekende TS nodig
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="caption">
                                                    {consultant.getekend
                                                        ? "ja"
                                                        : "neen"}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                Facturatie
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Facturatie
                                                data={consultant.facturatie}
                                                endCustomer={endCustomer}
                                                invoiceBy={invoiceBy}
                                                competenceCenter={
                                                    consultant.competenceCenter
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                {editable && (
                                    <CardActions>
                                        {onEdit && (
                                            <IconButton
                                                aria-label="Edit"
                                                onClick={() => onEdit(index)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        )}
                                        {onDelete && (
                                            <IconButton
                                                aria-label="Delete"
                                                onClick={() => onDelete(index)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </CardActions>
                                )}
                            </Card>
                        );
                    })}
                </Grid>
            </Hidden>

            <Hidden xsDown>
                {value.length > 0 && (
                    <Paper className={style.wrapper}>
                        <Grid
                            container
                            className={style.consultanttable}
                            alignItems="center"
                        >
                            <Grid item xs={2}>
                                <Typography variant="body2">
                                    Consultant / Profiel
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2">
                                    Aantal dagen
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2">
                                    Getekende TS nodig
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2">
                                    Sales Rate
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2">
                                    Facturatie
                                </Typography>
                            </Grid>
                            <Grid item xs={2} />
                            {value.map((consultant, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Grid item xs={12}>
                                            <Divider
                                                variant="middle"
                                                coomponent="div"
                                                style={{
                                                    marginTop: ".2em",
                                                    marginBottom: ".2em",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="caption">
                                                {consultant.consultant}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="caption">
                                                {(consultant.aantalDagen &&
                                                    consultant.aantalDagen
                                                        .trim &&
                                                    consultant.aantalDagen.trim()
                                                        .length &&
                                                    consultant.aantalDagen) ||
                                                    "--"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="caption">
                                                <Checkbox
                                                    disabled
                                                    value="getekend"
                                                    checked={
                                                        consultant.getekend
                                                    }
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="caption">
                                                €{consultant.salesRate}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Facturatie
                                                data={consultant.facturatie}
                                                endCustomer={endCustomer}
                                                invoiceBy={invoiceBy}
                                                competenceCenter={
                                                    consultant.competenceCenter
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            {editable && (
                                                <div>
                                                    {onEdit && (
                                                        <IconButton
                                                            aria-label="Edit"
                                                            onClick={() =>
                                                                onEdit(index)
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}
                                                    {onDelete && (
                                                        <IconButton
                                                            aria-label="Delete"
                                                            onClick={() =>
                                                                onDelete(index)
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                </div>
                                            )}
                                        </Grid>
                                    </React.Fragment>
                                );
                            })}
                        </Grid>
                    </Paper>
                )}
            </Hidden>
        </React.Fragment>
    );
};

export default ConsultantList;
