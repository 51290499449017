// @flow

// Import styling
import style from "./style.module.scss";

// Import libs
import React, { PureComponent } from "react";
import classnames from "classnames";
import {
    TextField,
    InputAdornment,
    Checkbox,
    FormControlLabel,
    Typography,
} from "@material-ui/core";

import { DateInterval } from "../";

export type Value = {
    interval: 1 | 2 | 3 | 4,
    price: string,
    variable: boolean,
};

export type Error = {
    price?: ?string,
};

// Define props
type Props = {
    value: Value,
    error?: ?Error,
    onChange: (value: Value) => void,
    onBlur: () => void,
};

type State = {
    touched: {
        price: boolean,
    },
};

/**
 * A ScheduledInvoicing component
 */
export default class Scheduled extends PureComponent<Props, State> {
    state = {
        touched: {
            price: false,
        },
    };

    /**
     * Render
     */
    render() {
        const { value, error, onChange, onBlur } = this.props;
        const { touched } = this.state;

        return (
            <div>
                <Typography variant="subtitle1">
                    Gebruik scheduled invoicing om op periodieke basis een
                    vooraf bepaald bedrag (de prijs) te factureren
                </Typography>
                <Typography variant="subtitle1">
                    Definiëer de prijs als variabel door overeenstemmende optie
                    aan te vinken.
                </Typography>
                <div className={classnames(style.wrapper)}>
                    <div className={classnames(style.fieldwrapper)}>
                        <div className={classnames(style.field)}>
                            <TextField
                                id="outlined-name"
                                label="Prijs"
                                value={value.price}
                                type="number"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            €
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={event => {
                                    const { touched } = this.state;

                                    this.setState({
                                        touched: {
                                            ...touched,
                                            price: true,
                                        },
                                    });

                                    onChange({
                                        ...value,
                                        price: event.target.value,
                                    });
                                }}
                                onBlur={onBlur}
                                error={touched.price && !!error?.price}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                disabled={value.variable}
                            />
                        </div>
                        <DateInterval
                            value={value.interval}
                            onChange={interval =>
                                onChange({ ...value, interval })
                            }
                            onBlur={onBlur}
                        />
                    </div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={value.variable}
                                onChange={event => {
                                    const { touched } = this.state;

                                    this.setState({
                                        touched: {
                                            ...touched,
                                            variable: true,
                                        },
                                    });

                                    onChange({
                                        ...value,
                                        variable: event.target.checked,
                                        price: "",
                                    });
                                }}
                            />
                        }
                        label="Variabel"
                    />
                </div>
            </div>
        );
    }
}
