// @flow

import * as yup from "yup";

const MSG_REQUIRED = "Dit is een verplicht veld";

// Tax schema
export default yup
    .object()
    .shape({
        bedrijfsvoorheffing: yup
            .string()
            .oneOf(["ja"])
            .required(MSG_REQUIRED),
        korteOmschrijving: yup.string().when("bedrijfsvoorheffing", {
            is: "ja",
            then: yup
                .string()
                .required(
                    "Omschrijving is verplicht wanneer een aanvraag geselecteerd is.",
                ),
        }),
        startBv: yup.number().when("bedrijfsvoorheffing", {
            is: "ja",
            then: yup
                .number()
                .moreThan(
                    0,
                    "Start datum is verplicht wanneer een aanvraag geselecteerd is",
                ),
        }),
        eindBv: yup.number().when("bedrijfsvoorheffing", {
            is: "ja",
            then: yup
                .number()
                .moreThan(
                    0,
                    "Eind datum is verplicht wanneer een aanvraag geselecteerd is",
                ),
        }),
    })
    .required("Tax is verplicht");
