// @flow

import * as yup from "yup";

const MSG_REQUIRED = "Dit is een verplicht veld";
const MSG_CHOICE = "Gelieve een keuze te maken";

export const Invoicing = {
    FIXED: 1,
    MILESTONES: 2,
    TIME_MATERIAL: 3,
    SHEDULED: 4,
};

export const Interval = ["MONTHLY", "QUARTERLY", "HALFYEARLY", "YEARLY"].reduce(
    (accumulator, current, index) => ({
        ...accumulator,
        [current]: index + 1,
    }),
    {},
);

// Milestone subschema
export const milestone = yup.object().shape({
    price: yup.string().required(MSG_REQUIRED),
    date: yup.number().moreThan(0, MSG_REQUIRED),
    description: yup.string(),
});

// Milestones subschema
export const milestones = yup
    .array()
    .of(milestone)
    .min(1, "Er moet minstens 1 milestone zijn.");

// Time and material subschema
export const timeAndMaterial = yup.object().shape({
    hours: yup
        .number()
        .oneOf([38, 40], MSG_CHOICE)
        .required(MSG_REQUIRED),
});

// Time and material subschema
export const fixedPrice = yup.object().shape({
    hours: yup
        .number()
        .oneOf([38, 40], MSG_CHOICE)
        .required(MSG_REQUIRED),
    price: yup.string().required(MSG_REQUIRED),
});

// Sheduled subschema
export const sheduled = yup.object().shape({
    variable: yup.boolean(),
    price: yup.string().when("variable", {
        is: true,
        then: yup.string(),
        otherwise: yup.string().required(MSG_REQUIRED),
    }),
    interval: yup
        .number()
        .oneOf(Object.values(Interval), MSG_CHOICE)
        .required(MSG_REQUIRED),
});

// Invoicing schema
export default yup.object().shape({
    type: yup
        .number()
        .oneOf(Object.values(Invoicing), MSG_CHOICE)
        .required(MSG_REQUIRED),
    // Fixed price
    fixedPrice: yup.object().when("type", {
        is: Invoicing.FIXED,
        then: fixedPrice,
    }),
    // Milestones
    milestones: yup.array().when("type", {
        is: Invoicing.MILESTONES,
        then: milestones,
    }),
    // TimeAndMaterial
    timeAndMaterial: yup.object().when("type", {
        is: Invoicing.TIME_MATERIAL,
        then: timeAndMaterial,
    }),
    // Scheduled
    sheduled: yup.object().when("type", {
        is: Invoicing.SHEDULED,
        then: sheduled,
    }),
});
