// @flow

import * as yup from "yup";

import general from "./general";
import invoicing from "./invoicing";
import { Invoicing as InvoicingTypes } from "./invoicing";
import consultant from "./consultant";
import tax from "./tax";

export default yup.object().shape({
    // General
    general,
    // Invoicing
    invoicing,
    // Tax
    tax,
    // Consultants
    consultants: yup.array().when("invoicing.type", {
        is: InvoicingTypes.SHEDULED,
        then: yup.array().of(consultant),
        otherwise: yup
            .array()
            .of(consultant)
            .min(1, "Er moet minstens 1 consultant zijn."),
    }),
});

export { general, invoicing };
