export default {
    general: {
        ccMailadres: "Personen die mee op de hoogte gehouden moeten worden",
        eindKlant:
            "Naam van de klant waar de consultant(s) op project zit(ten)",
        facturatie: "Partij die aan de eindklant zal factureren",
        sales: "Accountmanager van de klant in kwestie",
        projectNaam: "Naam van het project",
        refr: "Referentie / PO nummer van de klant",
        beginDatum: "Datum vanaf wanneer de timesheet code actief moet zijn",
        eindDatum: "Datum tot wanneer de timesheet code actief moet blijven",
        facturatiemail:
            "Vul hier het email adres in van de facturatiedienst van de eindklant",
        dailyRate: "Dagtarief van de freelancer",
        clientLocation: "Klant of locatie waar de freelancer werkt",
        orderNummer: "Vul hier je bestaande ordernummer in.",
    },
    tax: {
        korteOmschrijving: "max 300 characters",
        startBv: "Startdatum verplicht bij aanmerking",
        eindBv: "Einddatum verplicht bij aanmerking",
    },
    competenceCenter: {
        center: "Competence Center waar het contract op moet staan",
    },
    serviceProvider: {
        company: "Naam van het bedrijf waar de freelancer onder zit",
        legalForm: "Juridische vorm van het bedrijf",
        address: "Adres van het bedrijf",
        btwNumber: "BTW-nummer van het bedrijf",
        manager: "Naam van de manager van de dienstverlening",
    },
    freelancer: {
        name: "Naam van de freelancer",
        firstName: "voornaam van de freelancer",
        phoneNumber: "GSM-nummer van de freelancer",
        email: "E-mail van de freelancer",
        function: "Functie van de freelancer",
        nationality: "Nationaliteit van de freelancer",
        noticePeriod: "Opzegtermijn van het freelance contract",
        trailPeriod: "Proefperiode van het freelance contract",
        termOfPayment: "Intervalsperiode voor betaling",
        freelanceType: "Type van freelancewerk",
        beginDatum:
            "Datum vanaf wanneer het freelance contract actief moet zijn",
        eindDatum:
            "Datum tot wanneer het freelance contract actief moet blijven",
    },
    invoicing: {
        type:
            "Selecteer op welke manier de facturatie naar de klant zal gebeuren",
        timeAndMaterial: "Het regime dat de klant hanteert",
    },
    consultant: {
        general: {
            name: "Voornaam en naam of het profiel van de consultant",
            aantaldagen: "Aantal dagen voorzien dit project",
            getekend:
                "Vink dit aan wanneer er een getekende timesheet vereist is door de klant",
            salesRate:
                "De verkoopprijs op dagbasis die aan de eindklant gefactureerd zal worden",
            competenceCenter: "Competence center / bedrijf consultant",
        },
        invoicing: {
            infoCaption:
                "Gelieve enige tussenpartijen voor de facturatie aan te geven in onderstaande flow",
        },
    },
};
