// @flow

import React from "react";
import { Grid } from "@material-ui/core";

import AttachmentItem from "./AttachmentItem";

type Props = {
    attachments?: string[],
    onDelete: (value: string) => void,
};

const AttachmentList = ({ attachments, onDelete }: Props) => {
    if (attachments && attachments.length > 0) {
        return (
            <Grid container alignItems="center">
                {attachments.map((attachment, index) => (
                    <AttachmentItem
                        key={index}
                        attachment={attachment}
                        onDelete={onDelete}
                    />
                ))}
            </Grid>
        );
    } else {
        return null;
    }
};

export default AttachmentList;
