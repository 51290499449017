// @flow

import "isomorphic-fetch";
import ApolloClient from "apollo-boost";

import { getToken } from "./auth";

/**
 * Construct the apollo client
 */
export default new ApolloClient({
    uri: process.env.GATSBY_API_URL,
    request: operation =>
        operation.setContext({
            headers: {
                authorization: getToken() || undefined,
            },
        }),
});
