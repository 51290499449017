// @flow

import style from "./style.module.scss";

import classnames from "classnames";
import { TextField, MenuItem } from "@material-ui/core";
import React from "react";

type Value = 1 | 2 | 3 | 4;

type Props = {
    value: Value,
    onChange: (value: Value) => void,
    onBlur: () => void,
};

const DateInterval = ({ value, onChange, onBlur }: Props) => (
    <div className={classnames(style.field)}>
        <TextField
            select
            label="Interval"
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
            onBlur={onBlur}
            margin="dense"
            variant="outlined"
            fullWidth
        >
            <MenuItem value={1}>Maandelijks</MenuItem>
            <MenuItem value={2}>Per kwartaal</MenuItem>
            <MenuItem value={3}>Half jaarlijks</MenuItem>
            <MenuItem value={4}>Jaarlijks</MenuItem>
        </TextField>
    </div>
);

export default DateInterval;
