// @flow

// Import style
import style from "./style.module.scss";

// Import libs
import React, { Component } from "react";

import { General, Invoicing, Review } from "./Steps";

import {
    Button,
    Stepper,
    Step,
    StepLabel,
    Typography,
} from "@material-ui/core";

import { validate, isFieldValid } from "../../../validation/validate";
import consultantSchema from "../../../validation/schema/consultant";
import { type ConsultantType } from "../index";

// Define props
type PropsType = {
    onCloseDialog: (consultant: ?Object) => void,
    endCustomer: string,
    invoiceBy: string,
    consultant?: ConsultantType,
    fullScreen: boolean,
};

// Define state
type StateType = {
    fieldErrors: Object[],
    active: number,
    fields: Object,
    uuid: string,
};

/**
 * A General component
 */
export default class ConsultantStepper extends Component<PropsType, StateType> {
    GENERAL_FIELDS = [
        "consultant",
        "aantalDagen",
        "getekend",
        "salesRate",
        "competenceCenter",
    ];

    INVOICING_FIELDS = ["facturatie"];

    state = {
        fieldErrors: [],
        active: 0,
        fields: {},
        uuid: Date.now().toString(),
    };

    componentWillMount() {
        const { consultant } = this.props;
        if (consultant) {
            this.fillFormData(consultant);
        } else {
            this.resetFormData();
        }
    }

    resetFormData() {
        const fields_ = {};
        this.GENERAL_FIELDS.map(FIELD => {
            switch (FIELD) {
                case "getekend":
                    fields_[FIELD] = {
                        value: false,
                        error: null,
                        touched: false,
                    };
                    break;

                default:
                    fields_[FIELD] = {
                        value: "",
                        error: null,
                        touched: false,
                    };
                    break;
            }
        });

        this.INVOICING_FIELDS.map(FIELD => {
            switch (FIELD) {
                case "facturatie":
                    fields_[FIELD] = {
                        value: [],
                        error: null,
                        touched: false,
                    };
                    break;
                default:
                    fields_[FIELD] = {
                        value: "",
                        error: null,
                        touched: false,
                    };
                    break;
            }
        });

        this.setState({
            fields: fields_,
        });
    }

    fillFormData(consultant: ConsultantType) {
        const fields_ = {};
        this.GENERAL_FIELDS.map(FIELD => {
            fields_[FIELD] = {
                value: consultant[FIELD],
                error: null,
                touched: false,
            };
        });

        this.INVOICING_FIELDS.map(FIELD => {
            switch (FIELD) {
                case "facturatie":
                    fields_[FIELD] = {
                        value: consultant.facturatie,
                        error: null,
                        touched: false,
                    };
                    break;
                default:
                    fields_[FIELD] = {
                        value: "",
                        error: null,
                        touched: false,
                    };
                    break;
            }
        });

        this.setState({
            fields: fields_,
        });
    }

    validateField(schema: *, field: string, value: *, context: Object = {}) {
        const { fields } = this.state;
        this.setState({
            fields: {
                ...fields,
                [field]: {
                    ...fields[field],
                    error: null,
                    touched: true,
                },
            },
        });
        // Check if the field is valid
        if (!isFieldValid(schema, field, value, context)) {
            validate(schema, field, value, context, err => {
                this.setState({
                    fields: {
                        ...fields,
                        [field]: {
                            ...fields[field],
                            error: err.errors,
                            touched: false,
                        },
                    },
                });
            });
        } else {
            this.setState({
                fields: {
                    ...fields,
                    [field]: {
                        ...fields[field],
                        error: null,
                        touched: false,
                    },
                },
            });
        }
    }

    validationPerStep(id: number) {
        const { fields } = this.state;
        let temp = fields;
        const validFields = {};
        const factErrors = [];

        if (id === 0) {
            this.GENERAL_FIELDS.map(FIELD => {
                validFields[FIELD] = fields[FIELD].value;
            });

            if (consultantSchema.isValidSync(validFields)) {
                return true;
            } else {
                consultantSchema
                    .validate(validFields, { abortEarly: false })
                    .catch(err => {
                        err.inner.map(error => {
                            temp = {
                                ...temp,
                                [error.path]: {
                                    ...temp[error.path],
                                    error: error.errors,
                                    touched: false,
                                },
                            };
                        });
                        this.setState({
                            fields: temp,
                        });
                    });
            }
        } else {
            if (
                consultantSchema.fields.facturatie.isValidSync(
                    fields.facturatie.value,
                )
            ) {
                return true;
            } else {
                consultantSchema.fields.facturatie
                    .validate(fields.facturatie.value, { abortEarly: false })
                    .catch(err => {
                        err.inner.map(error => {
                            if (error.path === "salesRate") {
                                temp = {
                                    ...temp,
                                    [error.path]: {
                                        ...temp[error.path],
                                        error: error.errors,
                                        touched: false,
                                    },
                                };
                            } else {
                                factErrors.push(error.message);
                            }
                        });

                        if (factErrors.length > 0) {
                            temp = {
                                ...temp,
                                ["facturatie"]: {
                                    ...temp["facturatie"],
                                    error: factErrors,
                                    touched: false,
                                },
                            };
                        }

                        this.setState({
                            fields: temp,
                        });
                    });
            }
        }
    }

    handleFacturatie(uuid: string, name: string, value: string) {
        const { fields } = this.state;
        const temp = fields["facturatie"].value;
        const i = temp.findIndex(x => x.uuid === uuid);

        temp[i][name] = value;

        this.setState({
            fields: {
                ...fields,
                ["facturatie"]: { value: temp, touched: true, error: null },
            },
        });
    }

    handleDelete(uuid: string) {
        const { fields } = this.state;

        const temp = fields["facturatie"].value;
        const i = temp.findIndex(x => x.uuid === uuid);

        if (i >= 0) {
            temp.splice(i, 1);
        }

        this.setState({
            fields: {
                ...fields,
                ["facturatie"]: { value: temp, touched: true, error: null },
            },
        });
    }

    handleChange(name: string, value: *) {
        const { fields } = this.state;
        this.setState({
            fields: {
                ...fields,
                [name]: { ...fields[name], value, touched: true },
            },
        });
    }

    generateData() {
        const { fields } = this.state;
        const validFields = {};
        this.GENERAL_FIELDS.map(FIELD => {
            validFields[FIELD] = fields[FIELD].value;
        });
        this.INVOICING_FIELDS.map(FIELD => {
            validFields[FIELD] = fields[FIELD].value;
        });

        validFields.facturatie = validFields.facturatie.map(
            ({ step, value, custom }) => ({
                step,
                value,
                custom,
            }),
        );

        return validFields;
    }

    handleNext = () => {
        const { active } = this.state;
        const { onCloseDialog } = this.props;

        if (active === 2) {
            onCloseDialog(this.generateData());
        }

        if (this.validationPerStep(active)) {
            this.setState({
                active: active + 1,
            });
        }
    };

    handleBack = () => {
        const { active } = this.state;
        this.setState({
            active: active - 1,
        });
    };

    /**
     * Render
     */
    render() {
        const { active, fields } = this.state;
        const { invoiceBy, endCustomer, fullScreen } = this.props;
        const classname = fullScreen ? "fullscreen" : "";
        const stepperOrientation = fullScreen ? "vertical" : "horizontal";

        return (
            <div className={style[classname]}>
                <Stepper activeStep={active} orientation={stepperOrientation}>
                    <Step>
                        <StepLabel>Algemeen</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Facturatie</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Controle</StepLabel>
                    </Step>
                </Stepper>
                <div>
                    {active === 0 && (
                        <React.Fragment>
                            {!this.props.consultant ? (
                                <Typography variant="h6">
                                    Consultant of Profiel toevoegen
                                </Typography>
                            ) : (
                                <Typography variant="h6">
                                    Consultant of Profiel bewerken
                                </Typography>
                            )}
                            <Typography className={style.text}>
                                Vul onderstaande velden zo correct en compleet
                                als mogelijk in.
                            </Typography>
                            <General
                                consultant={fields["consultant"]}
                                aantalDagen={fields["aantalDagen"]}
                                getekend={fields["getekend"]}
                                salesRate={fields["salesRate"]}
                                competenceCenter={fields["competenceCenter"]}
                                onChange={this.handleChange.bind(this)}
                                onBlur={(field, value) =>
                                    this.validateField(
                                        consultantSchema,
                                        field,
                                        value,
                                    )
                                }
                            />
                        </React.Fragment>
                    )}
                    {active === 1 && (
                        <Invoicing
                            facturatieFlow={
                                fields["facturatie"].value.length
                                    ? fields["facturatie"].value
                                    : undefined
                            }
                            facturatie={fields["facturatie"]}
                            onChange={this.handleChange.bind(this)}
                            onBlur={(field, value) => {
                                this.validateField(
                                    consultantSchema,
                                    field,
                                    value,
                                );
                            }}
                            endCustomer={endCustomer}
                            invoiceBy={invoiceBy}
                            competenceCentre={fields["competenceCenter"].value}
                        />
                    )}
                    {active === 2 && (
                        <Review
                            data={this.generateData()}
                            competenceCenter={fields["competenceCenter"].value}
                            invoiceBy={invoiceBy}
                            endCustomer={endCustomer}
                        />
                    )}
                </div>
                <div className={style["button-bar"]}>
                    <Button
                        id="cancel"
                        className={style.button}
                        disabled={active === 0}
                        onClick={this.handleBack}
                    >
                        Back
                    </Button>
                    <Button
                        id="submit"
                        variant="contained"
                        color="primary"
                        className={style.button}
                        onClick={this.handleNext}
                    >
                        {active === 2 ? "Finish" : "Next"}
                    </Button>
                </div>
            </div>
        );
    }
}
