// @flow

import React from "react";
import { Grid, Typography, LinearProgress } from "@material-ui/core";
import { attachmentsHelper } from "../../utils";

import { type UploadFile, type Credentials } from "./index";

type Props = {
    attachment: UploadFile,
    credentials: Credentials,
    onDone: *,
    onError: *,
};

type State = {
    progress: number,
    uploading: boolean,
    error: boolean,
};

const getInitialValues = () => {
    return {
        progress: 0,
        uploading: false,
        error: false,
    };
};

class UploadProgress extends React.Component<Props, State> {
    state = getInitialValues();

    componentDidMount() {
        const { attachment, credentials, onDone, onError } = this.props;
        this.setState({
            ...this.state,
            uploading: true,
        });

        attachmentsHelper
            .uploadSingleFileToS3(
                attachment,
                credentials,
                this.handleProgressUpdate,
            )
            .then(() => {
                this.setState({
                    ...this.state,
                    uploading: false,
                });
                onDone(attachment);
            })
            .catch(error => {
                console.log(error);
                onError(attachment);
            });
    }

    handleProgressUpdate = ({ loaded, total }: *) => {
        const percentage = (loaded / total) * 100;
        this.setState({
            ...this.state,
            progress: percentage,
        });
    };

    render() {
        const { uploading, progress } = this.state;
        const { attachment } = this.props;
        return (
            <React.Fragment>
                <Grid item xs={8} md={4}>
                    <Typography variant="body1">
                        {attachment.file.name}
                    </Typography>
                </Grid>
                <Grid item xs={4} md={8}>
                    {uploading && (
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                        />
                    )}
                </Grid>
            </React.Fragment>
        );
    }
}

export default UploadProgress;
