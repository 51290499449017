// @flow

import style from "./style.module.scss";

import React, { PureComponent } from "react";
import classnames from "classnames";
import { TextField } from "@material-ui/core";
import helptexts from "../../../utils/helptexts";

export type Value = {
    company: string,
    legalForm: string,
    address: string,
    btwNumber: string,
    manager: string,
};

export type Error = {
    [key: string]: ?string,
};

type Props = {
    value: Value,
    error?: ?Error,
    onChange: (value: Value) => void,
    onBlur: () => void,
};

type State = {
    touched: {
        company: boolean,
        legalForm: boolean,
        address: boolean,
        btwNumber: boolean,
        manager: boolean,
    },
};

/**
 * FreelanceServiceProvider
 */
export default class FreelanceServiceProvider extends PureComponent<
    Props,
    State,
> {
    // State
    state = {
        touched: {
            company: false,
            legalForm: false,
            address: false,
            btwNumber: false,
            manager: false,
        },
    };

    /**
     * Change handler
     */
    handleChange = (field: string) => (inputValue: *) => {
        const { value, onChange } = this.props;
        const { touched } = this.state;

        this.setState({
            touched: {
                ...touched,
                [field]: true,
            },
        });

        onChange({
            ...value,
            [field]: inputValue,
        });
    };

    /**
     * Test if there is an error for this field
     */
    hasError(field: string): boolean {
        return !!this.getError(field);
    }

    /**
     * Get the error message for a field
     */
    getError(field: string): ?string {
        const { error } = this.props;
        const { touched } = this.state;

        if (touched[field] && error && error[field]) {
            return error[field];
        }
    }

    handleBlur = (field: string) => () => {
        const { touched } = this.state;
        const { onBlur } = this.props;
        this.setState(
            {
                touched: {
                    ...touched,
                    [field]: true,
                },
            },
            onBlur(),
        );
    };

    /**
     * Render
     */
    render() {
        const { value, onBlur } = this.props;

        return (
            <div className={classnames(style.wrapper)}>
                <div className={classnames(style.itemwrapper)}>
                    <div className={classnames(style.item)}>
                        <TextField
                            id="outlined-company"
                            label="Naam bedrijf"
                            value={value.company}
                            onChange={event =>
                                this.handleChange("company")(event.target.value)
                            }
                            margin="dense"
                            variant="outlined"
                            onBlur={onBlur}
                            helperText={helptexts.serviceProvider.company}
                            fullWidth
                        />
                    </div>
                    <div className={classnames(style.item)}>
                        <TextField
                            id="outlined-legal"
                            label="Juridische vorm"
                            value={value.legalForm}
                            onChange={event =>
                                this.handleChange("legalForm")(
                                    event.target.value,
                                )
                            }
                            margin="dense"
                            variant="outlined"
                            onBlur={onBlur}
                            helperText={helptexts.serviceProvider.legalForm}
                            fullWidth
                        />
                    </div>
                </div>
                <TextField
                    id="outlined-address"
                    label="Adres"
                    value={value.address}
                    onChange={event =>
                        this.handleChange("address")(event.target.value)
                    }
                    margin="dense"
                    variant="outlined"
                    onBlur={onBlur}
                    helperText={helptexts.serviceProvider.address}
                    fullWidth
                />
                <TextField
                    id="outlined-btw"
                    label="BTW-nummer"
                    value={value.btwNumber}
                    onChange={event =>
                        this.handleChange("btwNumber")(event.target.value)
                    }
                    margin="dense"
                    variant="outlined"
                    onBlur={onBlur}
                    helperText={helptexts.serviceProvider.btwNumber}
                    fullWidth
                />
                <TextField
                    id="outlined-manager"
                    label="Naam zaakvoerder/manager/director/..."
                    value={value.manager}
                    onChange={event =>
                        this.handleChange("manager")(event.target.value)
                    }
                    margin="dense"
                    variant="outlined"
                    onBlur={onBlur}
                    helperText={helptexts.serviceProvider.manager}
                    fullWidth
                />
            </div>
        );
    }
}
