// @flow

// Import style
import style from "./style.module.scss";
import helptexts from "../../../../../utils/helptexts";

// Import libs
import React, { Component } from "react";

import {
    TextField,
    FormControlLabel,
    Checkbox,
    // Typography,
    InputAdornment,
} from "@material-ui/core";

// Define field
type Field = {
    value: *,
    error: ?(string[]),
    touched: boolean,
};

// Define props
type PropsType = {
    consultant: Field,
    aantalDagen: Field,
    getekend: Field,
    salesRate: Field,
    competenceCenter: Field,
    onChange: (name: string, value: *) => void,
    onBlur: (field: string, value: *) => void,
};

/**
 * A General component
 */
export default class General extends Component<PropsType> {
    handleChange = (name: string) => (event: *) => {
        const { onChange } = this.props;
        onChange(name, event.target.value);
    };

    handleCheckChange = (name: string) => (event: *) => {
        const { onChange } = this.props;
        onChange(name, event.target.checked);
    };

    /**
     * Render
     */
    render() {
        const {
            consultant,
            aantalDagen,
            getekend,
            onBlur,
            salesRate,
            competenceCenter,
        } = this.props;
        return (
            <div className={style.wrapper}>
                <TextField
                    id="outlined-name"
                    label="Naam/Profiel Consultant(s)"
                    value={consultant.value}
                    onChange={this.handleChange("consultant")}
                    margin="dense"
                    variant="outlined"
                    onBlur={() => onBlur("consultant", consultant.value)}
                    error={!!consultant.error && !consultant.touched}
                    helperText={helptexts.consultant.general.name}
                    fullWidth
                />
                <TextField
                    id="outlined-name"
                    label="Competence Center"
                    value={competenceCenter.value}
                    type="text"
                    onChange={this.handleChange("competenceCenter")}
                    onBlur={() =>
                        onBlur("competenceCenter", competenceCenter.value)
                    }
                    error={
                        !!competenceCenter.error && !competenceCenter.touched
                    }
                    helperText={helptexts.consultant.general.competenceCenter}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                />
                <TextField
                    id="outlined-name"
                    label="Sales Rate Eindklant"
                    value={salesRate.value}
                    type="number"
                    onChange={this.handleChange("salesRate")}
                    onBlur={() => onBlur("salesRate", salesRate.value)}
                    error={!!salesRate.error && !salesRate.touched}
                    helperText={helptexts.consultant.general.salesRate}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">€</InputAdornment>
                        ),
                    }}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                />
                <TextField
                    id="outlined-name"
                    label="Aantal dagen - optioneel"
                    type="number"
                    value={aantalDagen.value}
                    onChange={this.handleChange("aantalDagen")}
                    margin="dense"
                    variant="outlined"
                    onBlur={() => onBlur("aantalDagen", aantalDagen.value)}
                    error={!!aantalDagen.error && !aantalDagen.touched}
                    helperText={helptexts.consultant.general.aantaldagen}
                    fullWidth
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={getekend.value}
                            onChange={this.handleCheckChange("getekend")}
                            value="getekend"
                        />
                    }
                    label="Getekende TS nodig"
                />
            </div>
        );
    }
}
