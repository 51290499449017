// @flow

import style from "./style.module.scss";

import React, { PureComponent } from "react";
import classnames from "classnames";
import {
    TextField,
    FormControl,
    MenuItem,
    Card,
    CardHeader,
    CardContent,
    Typography,
} from "@material-ui/core";
import helptexts from "../../../utils/helptexts";

export type Value = {
    name: string,
    firstName: string,
    phoneNumber: string,
    email: string,
    nationality: string,
    freelanceType: string,
};

export type Error = {
    [key: string]: ?string,
};

type Props = {
    value: Value,
    error?: ?Error,
    onBlur: () => void,
    onChange: (value: Value) => void,
};

type State = {
    labelWidth: 0,
    touched: {
        name: boolean,
        firstName: boolean,
        phoneNumber: boolean,
        email: boolean,
        nationality: boolean,
        freelanceType: boolean,
    },
};

/**
 * FreelanceGeneral
 */
export default class FreelanceGeneral extends PureComponent<Props, State> {
    // Initial state
    state = {
        labelWidth: 0,
        touched: {
            name: false,
            firstName: false,
            phoneNumber: false,
            email: false,
            nationality: false,
            freelanceType: false,
        },
    };

    handleChange = (field: string) => (inputValue: *) => {
        const { value, onChange } = this.props;
        const { touched } = this.state;

        this.setState({
            touched: {
                ...touched,
                [field]: true,
            },
        });

        onChange({
            ...value,
            [field]: inputValue,
        });
    };

    hasError(field: string): boolean {
        return !!this.getError(field);
    }

    getError(field: string): ?string {
        const { error } = this.props;
        const { touched } = this.state;

        if (touched[field] && error && error[field]) {
            return error[field];
        }
    }

    handleblur = (field: string) => () => {
        const { touched } = this.state;
        const { onBlur } = this.props;

        this.setState(
            {
                touched: {
                    ...touched,
                    [field]: true,
                },
            },
            onBlur(),
        );
    };

    /**
     * Render
     */
    render() {
        const { value, onBlur } = this.props;

        return (
            <div className={classnames(style.wrapper)}>
                <div className={classnames(style.namewrapper)}>
                    <div className={classnames(style.name)}>
                        <TextField
                            id="outlined-name"
                            label="Naam"
                            value={value.name}
                            onChange={event =>
                                this.handleChange("name")(event.target.value)
                            }
                            placeholder="Doe"
                            margin="dense"
                            variant="outlined"
                            onBlur={onBlur}
                            helperText={helptexts.freelancer.name}
                            fullWidth
                        />
                    </div>
                    <div className={classnames(style.name)}>
                        <TextField
                            id="outlined-firstName"
                            label="Voornaam"
                            value={value.firstName}
                            onChange={event =>
                                this.handleChange("firstName")(
                                    event.target.value,
                                )
                            }
                            placeholder="Jane"
                            margin="dense"
                            variant="outlined"
                            onBlur={onBlur}
                            helperText={helptexts.freelancer.firstName}
                            fullWidth
                        />
                    </div>
                </div>
                <TextField
                    id="outlined-phone"
                    label="Privé GSM-nummer"
                    value={value.phoneNumber}
                    onChange={event =>
                        this.handleChange("phoneNumber")(event.target.value)
                    }
                    placeholder="+32x xxx xxx"
                    margin="dense"
                    variant="outlined"
                    onBlur={onBlur}
                    helperText={helptexts.freelancer.phoneNumber}
                    fullWidth
                />
                <TextField
                    id="outlined-email"
                    label="Privé emailadres"
                    value={value.email}
                    onChange={event =>
                        this.handleChange("email")(event.target.value)
                    }
                    placeholder="jane.doe@xample.com"
                    margin="dense"
                    variant="outlined"
                    onBlur={onBlur}
                    helperText={helptexts.freelancer.email}
                    fullWidth
                />
                <TextField
                    id="outlined-nationality"
                    label="Nationaliteit"
                    value={value.nationality}
                    onChange={event =>
                        this.handleChange("nationality")(event.target.value)
                    }
                    margin="dense"
                    variant="outlined"
                    onBlur={onBlur}
                    helperText={helptexts.freelancer.nationality}
                    fullWidth
                />
                <div className={style.freelanceWrapper}>
                    <div className={classnames(style.freelance)}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                id="outlined-freelance-type"
                                select
                                label="Type freelance"
                                value={value.freelanceType}
                                onChange={event => {
                                    this.handleChange("freelanceType")(
                                        event.target.value,
                                    );
                                }}
                                margin="dense"
                                variant="outlined"
                                onBlur={onBlur}
                                helperText={helptexts.freelancer.freelanceType}
                                fullWidth
                            >
                                <MenuItem value="Contractor">
                                    Contractor
                                </MenuItem>
                                <MenuItem value="Contractor+">
                                    Contractor+
                                </MenuItem>
                                <MenuItem value="Freelance">Freelance</MenuItem>
                                <MenuItem value="Freelance Partner">
                                    Freelance Partner
                                </MenuItem>
                            </TextField>
                        </FormControl>
                    </div>
                    <div className={classnames(style.name)}>
                        <Card>
                            <CardHeader title="Types freelance" />
                            <CardContent>
                                <Typography paragraph>
                                    <u>Freelance Partner</u>: logt in met Cronos
                                    AD account, voor partner rechten moet er
                                    apart contact opgenomen worden met de
                                    controller
                                </Typography>
                                <Typography paragraph>
                                    <u>Freelancer</u>: logt in met Cronos AD
                                    account
                                </Typography>
                                <Typography paragraph>
                                    <u>Contractor</u>: logt in met lokale Cronos
                                    Central account
                                </Typography>
                                <Typography paragraph>
                                    <u>Contractor+</u>: logt in met lokale
                                    Cronos Central account, geen support vanuit
                                    Cronos SD
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}
