// @flow

// Import styles
import style from "./style.module.scss";

// Import libs
import React from "react";
import { Link } from "gatsby";
import {
    CircularProgress,
    Typography,
    AppBar,
    Toolbar,
    Tabs,
    Tab,
} from "@material-ui/core";
import classnames from "classnames";

import { Auth } from "../";

/**
 * A Header component
 */
const Header = () => {
    const activeStyles = {
        background: "white",
        color: "#000000",
    };

    return (
        <Auth>
            {profile => (
                <AppBar
                    position="static"
                    color="inherit"
                    className={classnames(style.appbar, style.override)}
                >
                    <Toolbar className={style.toolbar}>
                        <Typography
                            variant="h6"
                            color="inherit"
                            className={style.title}
                        >
                            XPLORE GROUP
                        </Typography>
                        <Tabs>
                            <Link
                                className={(style.title, style.link)}
                                activeStyle={activeStyles}
                                to="/"
                            >
                                <Tab label="Timesheetcode" />
                            </Link>
                            <Link
                                className={(style.title, style.link)}
                                activeStyle={activeStyles}
                                to="/freelance"
                            >
                                <Tab label="Freelance contract" />
                            </Link>
                        </Tabs>
                        <Typography
                            variant="h6"
                            color="inherit"
                            className={style.user}
                        >
                            {(profile && `${profile.name}`) || (
                                <CircularProgress size={20} />
                            )}
                        </Typography>
                    </Toolbar>
                </AppBar>
            )}
        </Auth>
    );
};

export default Header;
