// @flow

import * as yup from "yup";

const MSG_REQUIRED = "Dit is een verplicht veld";

// General schema
export default yup.object().shape({
    ccMailadres: yup.string().email(),
    eindKlant: yup.string().required(MSG_REQUIRED),
    facturatiemail: yup
        .string()
        .required(MSG_REQUIRED)
        .email(),
    facturatie: yup.string().required(MSG_REQUIRED),
    projectnaam: yup.string(),
    refr: yup.string(),
    eind: yup.number(),
    begin: yup.number().moreThan(0, MSG_REQUIRED),
    sales: yup.string(),
});
