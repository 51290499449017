// @flow

import React, { Fragment } from "react";
import { Grid, Typography, IconButton } from "@material-ui/core";

import { attachmentsHelper } from "../../utils";
import { Delete as DeleteIcon } from "@material-ui/icons";

type Props = {
    attachment: string,
    onDelete: (value: string) => void,
};

const AttachmentItem = ({ attachment, onDelete }: Props) => {
    return (
        <Fragment>
            <Grid item xs={11}>
                <Typography variant="body1">
                    {attachmentsHelper.removeMD5Prefix(attachment)}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <IconButton onClick={() => onDelete(attachment)}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Grid>
        </Fragment>
    );
};

export default AttachmentItem;
