// @flow

// Import styling
import style from "./style.module.scss";

// Import libs
import React from "react";
import classnames from "classnames";
import {
    TextField,
    Typography,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import moment from "moment";
import helptexts from "../../utils/helptexts";
import { DatePicker } from "material-ui-pickers";

// Value shema
export type Value = {
    bedrijfsvoorheffing: string,
    korteOmschrijving: string,
    startBv: number,
    eindBv: number,
};

// Define props
type Props = {
    onChange: (value: Value) => void,
    onBlur: () => void,
    value: Value,
    error: *,
};

const DATE_FORMAT = "DD/MM/YYYY";

/**
 * A General component
 */
const Tax = ({ value, error, onBlur, onChange }: Props) => (
    <div className={classnames(style.wrapper)}>
        <Typography variant="h6" className={classnames(style.title)}>
            Aanvraag vrijstelling doorstorting bedrijfsvoorheffing voor
            onderzoek en ontwikkeling
        </Typography>

        <div className={style.BvWrapper}>
            <FormControl component="fieldset">
                <FormLabel component="legend">
                    Opdracht komt in aanmerking vrijstelling doorstorting
                    bedrijfsvoorheffing voor onderzoek en ontwikkeling (enkel
                    voor Belgische medewerkers)
                </FormLabel>
                <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={value.bedrijfsvoorheffing}
                    onChange={event =>
                        onChange({
                            ...value,
                            bedrijfsvoorheffing: event.target.value,
                        })
                    }
                    onBlur={onBlur}
                    error={error?.bedrijfsvoorheffing}
                >
                    <FormControlLabel
                        value="ja"
                        control={<Radio color="primary" />}
                        label="Ja"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="nee"
                        control={<Radio color="primary" />}
                        label="Nee"
                        labelPlacement="end"
                    />
                </RadioGroup>
            </FormControl>
        </div>

        {value.bedrijfsvoorheffing == "ja" && (
            <>
                <TextField
                    id="outlined-name"
                    label="Korte omschrijving project / activiteit"
                    value={value.korteOmschrijving}
                    onChange={event =>
                        onChange({
                            ...value,
                            korteOmschrijving: event.target.value,
                        })
                    }
                    margin="dense"
                    variant="outlined"
                    multiline
                    rowsMax={5}
                    maxLength="300"
                    fullWidth
                    onBlur={() => onBlur()}
                    helperText={helptexts.tax.korteOmschrijving}
                    error={error?.korteOmschrijving}
                />
                <div className={classnames(style.datewrapper)}>
                    <div className={classnames(style.date)}>
                        <DatePicker
                            id="outlined-name"
                            label="Startdatum"
                            invalidLabel="dd/mm/yyyy"
                            value={
                                value.startBv
                                    ? moment.unix(value.startBv)
                                    : null
                            }
                            onChange={event =>
                                onChange({
                                    ...value,
                                    startBv: event.unix(),
                                })
                            }
                            format={DATE_FORMAT}
                            margin="dense"
                            variant="outlined"
                            onBlur={() => onBlur()}
                            error={error?.startBv}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            keyboard
                            disableOpenOnEnter
                            mask={value =>
                                value
                                    ? [
                                          /\d/,
                                          /\d/,
                                          "/",
                                          /\d/,
                                          /\d/,
                                          "/",
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                      ]
                                    : []
                            }
                            placeholder="dd/mm/jjjj"
                        />
                    </div>
                    <div className={classnames(style.date)}>
                        <DatePicker
                            id="outlined-name"
                            label="Geschatte einddatum"
                            invalidLabel="dd/mm/yyyy"
                            value={
                                value.eindBv ? moment.unix(value.eindBv) : null
                            }
                            onChange={event =>
                                onChange({
                                    ...value,
                                    eindBv: event.unix(),
                                })
                            }
                            format={DATE_FORMAT}
                            margin="dense"
                            variant="outlined"
                            onBlur={() => onBlur()}
                            error={error?.eindBv}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            keyboard
                            disableOpenOnEnter
                            mask={value =>
                                value
                                    ? [
                                          /\d/,
                                          /\d/,
                                          "/",
                                          /\d/,
                                          /\d/,
                                          "/",
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                      ]
                                    : []
                            }
                            placeholder="dd/mm/jjjj"
                        />
                    </div>
                </div>
            </>
        )}
    </div>
);

export default Tax;
