// @flow

import { Component, type Node } from "react";

import { login } from "../../utils/auth";

type Profile = {
    name: string,
    email: string,
    company: string,
};

type Props = {
    children: (profile: ?Profile) => Node,
};

type State = {
    profile: ?Profile,
};

/**
 * Component that injects auth profile into the app
 * or redirects when not authenticated or indicates a
 * loading state when busy
 *
 * Usage:
 *
 * <Auth>
 *  {profile => (
 *      <Fragment>
 *          {profile && <p>User is: {profile.name}</p> || <p>logging in...</p>}
 *      </Fragment>
 *  )}
 * </Auth>
 */
export default class Auth extends Component<Props, State> {
    // Initial state
    state = {
        profile: null,
    };

    /**
     * On component mount
     */
    componentDidMount() {
        // Do some auth
        if (!window.skipLogin) {
            login().then(profile => this.setState({ profile }));
        }
    }

    /**
     * Render
     */
    render() {
        const { children } = this.props;
        const { profile } = this.state;

        return children(profile);
    }
}
