// @flow

// Import styling
import style from "./style.module.scss";

// Import libs
import React, { PureComponent } from "react";
import classnames from "classnames";
import { Typography, Button, Divider } from "@material-ui/core";

import { Milestone } from "../";
import { type Value } from "../Milestone";

// Define props
type Props = {
    value: Value[],
    error: *,
    onChange: (Value[]) => void,
    onBlur: () => void,
};

/**
 * A FixedPriceMilestones component
 */
export default class FixedPriceMilestones extends PureComponent<Props> {
    /**
     * Add handler
     */
    handleAdd() {
        const { value, onChange } = this.props;

        onChange([
            ...value,
            {
                price: "",
                date: 0,
            },
        ]);
    }

    /**
     * Change handler
     */
    handleChange(index: number, value: Value) {
        const { value: original, onChange } = this.props;

        onChange(
            original.map((entry, current) =>
                current === index ? value : entry,
            ),
        );
    }

    /**
     * Deletion handler
     */
    handleDelete(index: number) {
        const { value, onChange } = this.props;

        onChange(value.filter((_, current) => current != index));
    }

    /**
     * Error getter
     */
    getError(index: number) {
        const { error } = this.props;

        if (error?.length) {
            return error[index];
        }
    }

    /**
     * Render
     */
    render() {
        const { value, error, onBlur } = this.props;

        return (
            <div className={classnames(style.wrapper)}>
                <Typography variant="subtitle1">
                    Vul hier de gewenste milestones en het corresponderende
                    facturatiebedrag in.
                </Typography>
                <Typography variant="subtitle1">
                    Voeg indien mogelijk ook een omschrijving / naam toe voor de
                    verschillende milestones.
                </Typography>
                {typeof error === "string" && (
                    <Typography color="error">{error}</Typography>
                )}
                <Divider className={classnames(style.divider)} />
                {value.map((milestone, index) => (
                    <Milestone
                        key={index}
                        value={milestone}
                        error={this.getError(index)}
                        onChange={value => this.handleChange(index, value)}
                        onDelete={() => this.handleDelete(index)}
                        onBlur={onBlur}
                    />
                ))}
                <Button
                    color="primary"
                    variant="contained"
                    className={classnames(style.button)}
                    onClick={() => this.handleAdd()}
                >
                    Milestone toevoegen
                </Button>
            </div>
        );
    }
}
