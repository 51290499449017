// @flow

// Import style
import style from "./style.module.scss";

// Import libs
import React, { Component } from "react";
import { Typography, Divider, Grid } from "@material-ui/core";

import Facturatie from "../../../Facturatie";

// Define props
type PropsType = {
    data: Object,
    competenceCenter: string,
    invoiceBy: string,
    endCustomer: string,
};

/**
 * A General component
 */
export default class Review extends Component<PropsType> {
    /**
     * Render
     */
    render() {
        const { data, competenceCenter, invoiceBy, endCustomer } = this.props;
        return (
            <div className={style.wrapper}>
                <Typography variant="h6">Controleer de gegevens</Typography>
                <Typography className={style.text}>
                    Gelieve de gegevens voor de consultant of het profiel goed
                    te controlleren.
                </Typography>
                <Divider variant="middle" className={style.divider} />

                {data.consultant && (
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}>
                            <Typography>Consultant / Profiel:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={style.bold}>
                                {data.consultant}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {data.aantalDagen && (
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}>
                            <Typography>Aantal Dagen: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={style.bold}>
                                {data.aantalDagen}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {data.getekend && (
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}>
                            <Typography>Getekende TS nodig: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={style.bold}>
                                {data.getekend ? "JA" : "NEE"}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {data.salesRate && (
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}>
                            <Typography>Sales Rate Eindklant: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={style.bold}>
                                {data.salesRate}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {data.facturatie && (
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}>
                            <Typography>Facturatie: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Facturatie
                                data={data.facturatie}
                                competenceCenter={competenceCenter}
                                invoiceBy={invoiceBy}
                                endCustomer={endCustomer}
                                showFull
                            />
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }
}
