// @flow

// Import styling
import style from "./style.module.scss";

// Import libs
import React from "react";
import classnames from "classnames";
import { TextField, MenuItem, Typography, Divider } from "@material-ui/core";

import { Invoicing as InvoicingTypes } from "../../validation/schema/invoicing";
import helptexts from "../../utils/helptexts";

// Import custom components
import {
    FixedPrice,
    FixedPriceMilestones,
    TimeAndMaterial,
    Scheduled,
} from "../";

import { type Value as MilestoneValue } from "../Milestone";
import { type Value as ScheduledValue } from "../Scheduled";
import { type Value as TimeAndMaterialValue } from "../TimeAndMaterial";
import { type Value as FixedPriceValue } from "../FixedPrice";

// Value shema
export type Value = {
    type: 1 | 2 | 3 | 4,
    fixedPrice: FixedPriceValue,
    milestones: MilestoneValue[],
    timeAndMaterial: TimeAndMaterialValue,
    sheduled: ScheduledValue,
};

// Define props
type Props = {
    onChange: (value: Value) => void,
    onBlur: () => void,
    value: Value,
    error: *,
};

/**
 * A General component
 */
const Invoicing = ({ value, error, onBlur, onChange }: Props) => (
    <div className={classnames(style.wrapper)}>
        <Divider className={classnames(style.divider)} />
        <Typography variant="h6" className={classnames(style.title)}>
            Soort invoicing
        </Typography>
        <TextField
            id="outlined-select-invoicing"
            select
            label="Type of invoicing"
            value={value.type}
            onChange={event =>
                onChange({
                    ...value,
                    type: event.target.value,
                })
            }
            helperText={helptexts.invoicing.type}
            margin="dense"
            variant="outlined"
            onBlur={() => onBlur()}
            fullWidth
        >
            <MenuItem value={1}>Fixed price</MenuItem>
            <MenuItem value={2}>Fixed price met milestones</MenuItem>
            <MenuItem value={3}>Time &amp; Material</MenuItem>
            <MenuItem value={4}>Scheduled Invoicing</MenuItem>
        </TextField>
        {value.type === InvoicingTypes.FIXED && (
            <FixedPrice
                value={value.fixedPrice}
                onBlur={onBlur}
                onChange={fixedPrice =>
                    onChange({
                        ...value,
                        fixedPrice,
                    })
                }
                error={error?.fixedPrice}
            />
        )}
        {value.type === InvoicingTypes.MILESTONES && (
            <FixedPriceMilestones
                value={value.milestones}
                error={error?.milestones}
                onChange={milestones =>
                    onChange({
                        ...value,
                        milestones,
                    })
                }
                onBlur={onBlur}
            />
        )}
        {value.type === InvoicingTypes.TIME_MATERIAL && (
            <TimeAndMaterial
                value={value.timeAndMaterial}
                onBlur={onBlur}
                onChange={timeAndMaterial =>
                    onChange({
                        ...value,
                        timeAndMaterial,
                    })
                }
            />
        )}
        {value.type === InvoicingTypes.SHEDULED && (
            <Scheduled
                value={value.sheduled}
                error={error?.sheduled}
                onBlur={onBlur}
                onChange={sheduled =>
                    onChange({
                        ...value,
                        sheduled,
                    })
                }
            />
        )}
    </div>
);

export default Invoicing;
