// @flow

// Import style
import style from "./style.module.scss";

// Import libs
import React, { Component } from "react";

import {
    Typography,
    Divider,
    Chip,
    Icon,
    IconButton,
    TextField,
} from "@material-ui/core";
// import { LabelImportant as LabelImportantIcon } from "@material-ui/icons";

// Define field
type Field = {
    value: Object[],
    error: ?(string[]),
    touched: boolean,
};

export type InvoiceStep = {
    value: string,
    step: number,
    custom: boolean,
};

// Define props
type PropsType = {
    onChange: (name: string, value: *) => void,
    onBlur: (field: string, value: *) => void,
    facturatie: Field,
    facturatieFlow?: InvoiceStep[],
    endCustomer: string,
    invoiceBy: string,
    competenceCentre: string,
};

type stateType = {
    facturatieFlow: InvoiceStep[],
};

const getInitialValues = () => ({
    facturatieFlow: [],
});

/**
 * A General component
 */
export default class Invoicing extends Component<PropsType, stateType> {
    state = getInitialValues();

    componentDidMount() {
        const { facturatieFlow } = this.props;
        if (facturatieFlow) {
            this.setState({
                facturatieFlow: facturatieFlow,
            });
        }
    }

    handleChange = (name: string) => (event: *) => {
        const { onChange } = this.props;
        onChange(name, event.target.value);
    };

    getFieldError(id: number) {
        const { facturatie } = this.props;
        let temp = "";
        if (facturatie.error) {
            facturatie.error.map(err => {
                if (err.startsWith(`[${id}].`)) {
                    temp = err;
                }
            });
        }
        if (temp !== "") {
            return temp;
        } else {
            return null;
        }
    }

    handleDelete = (index: number) => () => {
        const adjustedFacturatieFlow = this.state.facturatieFlow.map(
            (facturatieStep, facturatieIndex) => {
                if (facturatieIndex > index) {
                    facturatieStep.step -= 1;
                }
                return facturatieStep;
            },
        );
        adjustedFacturatieFlow.splice(index, 1);
        this.setState({
            facturatieFlow: adjustedFacturatieFlow,
        });
        this.props.onChange("facturatie", adjustedFacturatieFlow);
    };

    handleAdd = (index: number) => () => {
        const invoiceSteps = this.state.facturatieFlow;
        const adjustedFacturatieFlow = invoiceSteps.map(
            (facturatieStep, facturatieIndex) => {
                if (index !== 0) {
                    if (facturatieIndex <= index) {
                        return facturatieStep;
                    }
                }

                facturatieStep.step += 1;
                return facturatieStep;
            },
        );
        adjustedFacturatieFlow.splice(index, 0, {
            step: index + 1,
            value: "",
            custom: true,
        });
        this.setState({
            facturatieFlow: adjustedFacturatieFlow,
        });
        this.props.onChange("facturatie", adjustedFacturatieFlow);
    };

    handleFacturatieStapChange = (index: number) => (event: *) => {
        const facturatieFlow = [...this.state.facturatieFlow];
        const facturatieStap = facturatieFlow[index];
        facturatieStap.value = event.target.value;
        this.props.onChange("facturatie", facturatieFlow);
    };

    isMaximumReached = () => {
        return (
            this.state.facturatieFlow.filter(
                facturatieStep => facturatieStep.custom,
            ).length >= 3
        );
    };

    /**
     * Render
     */
    render() {
        const { facturatieFlow } = this.state;
        const { endCustomer, invoiceBy, competenceCentre } = this.props;
        return (
            <div className={style.wrapper}>
                <Typography variant="h6">Facturatie via</Typography>
                <Typography>
                    Hieronder ziet u de huidige facturatieflow. Klik op het
                    <Icon color="primary" className={style.texticon}>
                        label_important
                    </Icon>
                    icoon tussen twee partijen om een extra partij toe te
                    voegen. U kan maximaal 3 tussenpartijen toevoegen.
                </Typography>

                <Divider variant="middle" className={style.divider} />
                <div className={style.flowWrapper}>
                    <Chip label={competenceCentre} />
                    <IconButton
                        color="primary"
                        onClick={this.handleAdd(0)}
                        disabled={this.isMaximumReached()}
                    >
                        <Icon
                            color={
                                this.isMaximumReached() ? "disabled" : "primary"
                            }
                        >
                            label_important
                        </Icon>
                    </IconButton>
                    {facturatieFlow
                        .sort((fs1, fs2) => fs1.step - fs2.step)
                        .map((facturatieStep, index) => (
                            <React.Fragment key={`step_${facturatieStep.step}`}>
                                {facturatieStep.custom ? (
                                    <div className={style.editcontainer}>
                                        <TextField
                                            id="outlined-name"
                                            type="text"
                                            label="Naam tussenpartij"
                                            value={facturatieStep.value}
                                            onChange={this.handleFacturatieStapChange(
                                                index,
                                            )}
                                            margin="dense"
                                            variant="outlined"
                                            onBlur={() =>
                                                this.props.onBlur(
                                                    "facturatie",
                                                    facturatieFlow,
                                                )
                                            }
                                            error={this.getFieldError(index)}
                                        />
                                        <IconButton
                                            color="default"
                                            onClick={this.handleDelete(index)}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </div>
                                ) : (
                                    <Chip
                                        label={
                                            facturatieStep.value ===
                                            "__INVOICEBY__"
                                                ? invoiceBy
                                                : facturatieStep.value
                                        }
                                    />
                                )}
                                <IconButton
                                    color="primary"
                                    onClick={this.handleAdd(index + 1)}
                                    disabled={this.isMaximumReached()}
                                >
                                    <Icon
                                        color={
                                            this.isMaximumReached()
                                                ? "disabled"
                                                : "primary"
                                        }
                                    >
                                        label_important
                                    </Icon>
                                </IconButton>
                            </React.Fragment>
                        ))}
                    <Chip label={invoiceBy || "N/A"} />
                    <Icon color="disabled" className={style.flowicon}>
                        label_important
                    </Icon>
                    <Chip label={endCustomer || "N/A"} />
                </div>
            </div>
        );
    }
}
