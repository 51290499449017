// @flow

import "../../styles/global.scss";
import style from "./style.module.scss";

import React, { Fragment, type Node } from "react";

import { Header } from "../";

type Props = {
    children: Node,
};

/**
 * Page layout and global styling
 */
const Layout = ({ children }: Props) => (
    <Fragment>
        <Header />
        <main className={style.element}>{children}</main>
    </Fragment>
);

export default Layout;

/**
 * Export as gatsby page wrapper
 */
export const wrapper = ({ element }: *) => <Layout>{element}</Layout>;
