// @flow

// Import style
import style from "./style.module.scss";

// Import libs
import React, { Component } from "react";
import { Tooltip } from "@material-ui/core";
import { ChevronRight as ChevronRightIcon } from "@material-ui/icons";

// Define props
type PropsType = {
    data: Object[],
    showFull?: boolean,
    competenceCenter: string,
    endCustomer: string,
    invoiceBy: string,
};

/**
 * A General component
 */
export default class Facturatie extends Component<PropsType> {
    /**
     * Render
     */
    render() {
        const {
            data,
            showFull,
            competenceCenter,
            endCustomer,
            invoiceBy,
        } = this.props;
        const sortedInvoiceData = data.sort(
            (invoiceStep1, invoiceStep2) =>
                invoiceStep1.step - invoiceStep2.step,
        );
        return (
            <div>
                {data.length > 0 && !showFull && (
                    <Tooltip
                        title={
                            <div className={style.wrapper}>
                                <div className={style.innerwrapper}>
                                    <span className={style.company}>
                                        {competenceCenter}
                                    </span>
                                    <ChevronRightIcon className={style.icon} />
                                </div>
                                {sortedInvoiceData.map((fac, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className={style.innerwrapper}
                                        >
                                            <span className={style.company}>
                                                {fac[
                                                    Object.keys(fac)[1]
                                                ].toUpperCase()}
                                            </span>
                                            <ChevronRightIcon
                                                className={style.icon}
                                            />
                                        </div>
                                    );
                                })}
                                <div className={style.innerwrapper}>
                                    <span className={style.company}>
                                        {invoiceBy}
                                    </span>
                                    <ChevronRightIcon className={style.icon} />
                                </div>
                                <div className={style.innerwrapper}>
                                    <span className={style.company}>
                                        {endCustomer}
                                    </span>
                                </div>
                            </div>
                        }
                    >
                        <div className={style.wrapper}>
                            <div className={style.innerwrapper}>
                                <span className={style.company}>
                                    {competenceCenter}
                                </span>
                                <ChevronRightIcon className={style.icon} />
                                <span className={style.company}>...</span>
                                <ChevronRightIcon className={style.icon} />
                                <span className={style.company}>
                                    {endCustomer}
                                </span>
                            </div>
                        </div>
                    </Tooltip>
                )}

                {(sortedInvoiceData.length === 0 || showFull) && (
                    <div className={style.wrapper}>
                        <div className={style.innerwrapper}>
                            <span className={style.company}>
                                {competenceCenter || "N/A"}
                            </span>
                            <ChevronRightIcon className={style.icon} />
                        </div>
                        {sortedInvoiceData.map((fac, i) => {
                            return (
                                <div key={i} className={style.innerwrapper}>
                                    <span className={style.company}>
                                        {fac[Object.keys(fac)[1]].toUpperCase()}
                                    </span>
                                    <ChevronRightIcon className={style.icon} />
                                </div>
                            );
                        })}
                        <div className={style.innerwrapper}>
                            <span className={style.company}>
                                {invoiceBy || "N/A"}
                            </span>
                            <ChevronRightIcon className={style.icon} />
                        </div>
                        <div className={style.innerwrapper}>
                            <span className={style.company}>
                                {endCustomer || "N/A"}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
