import * as yup from "yup";

export const isFieldValid = (schema, path, value, options = {}) => {
    yup.reach(schema, path).isValidSync(value, { context: options });
};

export const validate = (schema, path, value, options = {}, callback) => {
    yup.reach(schema, path)
        .validate(value, { context: options, abortEarly: false })
        .catch(err => callback(err));
};

export default { validate, isFieldValid };
