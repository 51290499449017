// @flow

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
/* eslint-env browser */

import { parse as parseOAuth2Grant } from "./src/utils/auth";
import { wrapper as layoutWrapper } from "./src/components/Layout";
import { wrapper as apiProviderWrapper } from "./src/components/ApiProvider";

window.skipLogin = false;

if (
    location.host === "freelancer.xploregroup.be" ||
    location.host === "freelance.xploregroup.be"
) {
    location.href = "https://timesheetcodes.xploregroup.be/freelance";
    window.skipLogin = true;
}

/**
 * Provide a wrapper
 */
export const wrapPageElement = layoutWrapper;

/**
 * Inject providers into root
 */
export const wrapRootElement = apiProviderWrapper;

/**
 * On client boot, check qs for grant
 */
export const onClientEntry = () => {
    if (!window.skipLogin) {
        parseOAuth2Grant();
    }
};
